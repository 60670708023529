import { Button } from 'react-bootstrap';
import { useState, useEffect } from 'react'
import TextEditor from '../../Editor/TextEditor';
import Form from 'react-bootstrap/Form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from "react-helmet";
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Axios } from '../../components/common/Axios';
import { notify, notifyErr } from '../../components/common/norify';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { FaTrashAlt } from 'react-icons/fa';


const PortfolioUpdate = () => {

    const apiUrl = process.env.REACT_APP_API_URL;

    const { id } = useParams();
    //const [categories, setCategories] = useState([]);
    const [pending, setPending] = useState(true);
    const [prevImages, setPrevImages] = useState([])
    const [images, setImages] = useState('');
    const [updatedContent, setUpdatedContent] = useState('');
    const [updatedDescription, setUpdatedDescription] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        subtitle: '',
        content: '',
        description: '',
    });



    const getData = async () => {
        // try {
        //   const categoryData = await Axios.get(`/api/portfolio`);
        //   let category = categoryData.data.data;
        //   setCategories(category);
        //   setPending(false);
        // } catch (error) {
        //   // Handle errors
        //   console.error('Error:', error);
        // }

        try {
            const portfolioData = await Axios.get(`/api/portfolio/${id}`);
            let portfolio = portfolioData.data;
            setFormData({
                ['id']: portfolio._id,
                ['name']: portfolio.name,
                ['subtitle']: portfolio.subtitle,
                ['content']: portfolio.content,
                ['description']: portfolio.description,
            });
            // setId(product._id)
            setPrevImages(portfolio.image)
            setUpdatedContent(portfolio.content);
            setUpdatedDescription(portfolio.description);

        } catch (error) {
            // Handle errors
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        getData();
        window.scrollTo(0, 0);
    }, [id]);

    useEffect(() => {
        handleInputChange('content', updatedContent);
        handleInputChange('description', updatedDescription);
    }, [updatedContent, updatedDescription])



    const handleClose = () => {
        setFormData({
            name: '',
            subtitle: '',
            content: '',
            description: '',
        });

    }


    const handleImageChange = (e) => {
        const files = e.target.files;
        const imagesArray = [];

        for (let index = 0; index < files.length; index++) {
            const imageFile = files[index];
            imagesArray.push(imageFile);
        }

        // // Set the "image" field in the formData state to the array of images
        // setFormData({ ...formData, image: imagesArray });
        setImages(imagesArray);
    };



    const { register, handleSubmit, formState: { errors } } = useForm();
    // const onSubmit = (data) => {
    //   console.log(data)
    // }

    const handleInputChange = (fieldName, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [fieldName]: value,
        }));
    };


    //   useEffect(() => {
    //     handleInputChange('long_description', longDes);
    //   }, [longDes])


    const onSubmit = async () => {

        try {
            const response = await Axios.put(`/api/portfolio/${id}`, formData);
            if (response.status === 200 || 201) {
                notify('Portfolio Update Successfully');
                setTimeout(() => {
                    getData();
                }, 1500);
            }
        } catch (error) {
            notifyErr('Something went wrong')
            console.error(error);
        }

    }



    const onUpdate = async () => {
        const imagesToUpload = images;
        const formDataToPost = new FormData();
        //formDataToPost.append('id', id);

        for (let index = 0; index < imagesToUpload.length; index++) {
            formDataToPost.append('image', imagesToUpload[index]);
        }

        try {
            const response = await Axios.put(`/api/updatePortfolioImage/${id}`, formDataToPost);
            if (response.status === 200 || 201) {
                notify('Portfolio Update Successfully');
                setTimeout(() => {
                    getData();
                }, 1500);
            }
        } catch (error) {
            notifyErr('Something went wrong')
            console.error(error);
        }

    }


    const handleDelete = async (item) => {
        try {
            const response = await Axios.delete(`/api/deletePortfolioImage/${id}/${item}`);
            if (response.status === 200 || 201) {
                notify('Portfolio Update Successfully');
                setTimeout(() => {
                    getData();
                }, 1500);
            }
        } catch (error) {
            notifyErr('Something went wrong')
            console.error(error);
        }
    }



    return (
        <>
            <div className='update-page'>
                <Helmet>
                    <title>Update Portfolio</title>
                </Helmet>
                <section className="portfolio-add">
                    <div className="d-flex justify-content-between align-items-center pt-6 pb-2 m-2">
                        <div className="">
                            <span className='fw-bold'>Update Portfolio</span>
                        </div>

                    </div>
                </section>
                <ToastContainer />
                <section classNAme="card  cardSectionBorder mt-3">
                    <div className='m-2'>
                        <Form className='row' onSubmit={handleSubmit(onSubmit)}>

                            <Form.Group className="mt-2 mb-3 col-6">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Name"
                                    value={formData?.name || ''}
                                    {...register("name", {
                                        required: false,
                                        onChange: (e) => handleInputChange('name', e.target.value)
                                    })}
                                />

                                {errors.name && (
                                    <div className='errMsg text-danger'>Name is required</div>
                                )}
                            </Form.Group>

                            <Form.Group className="mt-2 mb-3 col-6">
                                <Form.Label>subtitle</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Subtitle"
                                    value={formData?.subtitle || ''}
                                    {...register("subtitle", {
                                        required: false,
                                        onChange: (e) => handleInputChange('subtitle', e.target.value)
                                    })}
                                />

                                {errors.subtitle && (
                                    <div className='errMsg text-danger'>subtitle is required</div>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                                <Form.Label>Content</Form.Label>
                                <TextEditor data={updatedContent} setUpdatedContent={setUpdatedContent} />
                            </Form.Group>

                            <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                                <Form.Label>Description</Form.Label>
                                <TextEditor data={updatedDescription} setUpdatedContent={setUpdatedDescription} />
                            </Form.Group>

                            <hr></hr>
                            <div className='btn-d d-flex justify-content-center'>
                                <Button type='submit' className='text-white w-20' variant='contained' style={{ backgroundColor: '#0C0833', borderRadius: '1' }} >
                                    Update Portfolio
                                </Button>
                            </div>
                        </Form>

                    </div>


                </section >

                <section className='card cardSectionBorder mt-3 '>
                    <div className='portfolio-images m-2'>

                        <Form.Group
                            className="mt-2"
                        >
                            <div className='row m-0'>
                                <div className='col-lg-12 mb-3 p-3'>
                                    <Form.Label>Portfolio Images</Form.Label>
                                    <div className='m-2 text-center'>
                                        <ImageList cols={6}>
                                            {prevImages?.map((item, index) => (
                                                <ImageListItem key={index} style={{ border: '2px solid #2e2d3c' }} className='position-relative'>
                                                    <Button className='text-white bg-danger border-0 position-absolute'
                                                        onClick={() => handleDelete(item)}
                                                        style={{ borderRadius: '0', right: '0', top: '0' }}>
                                                        <FaTrashAlt />
                                                    </Button>
                                                    <img
                                                        srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                                        // src={`${URL.createObjectURL(item)}?w=164&h=164&fit=crop&auto=format`}
                                                        src={`${apiUrl}/static/assets/${item}`}
                                                        alt={`image-${index}`}
                                                        loading="lazy" className='object-fit-contain'
                                                    />
                                                </ImageListItem>
                                            ))}
                                        </ImageList>
                                    </div>
                                </div>

                                <div className='text-left col-lg-6 col-12'>
                                    <Form.Label>Add Image</Form.Label>
                                    <Form.Control type="file" name="file" multiple
                                        onChange={(e) => handleImageChange(e)}
                                    />

                                    {errors.Image && (
                                        <div className='errMsg text-danger'>Please Select Image</div>
                                    )}

                                </div>
                                <div className='col-12 mt-3'>
                                    <div className='d-flex justify-content-start gap-3 align-items-center mb-3'>
                                        <Form.Label>Image Preview</Form.Label>
                                        {images?.length > 0 &&
                                            <Button className='text-white w-20 bg-danger' variant='contained'
                                                onClick={() => setImages('')}
                                            >
                                                <FaTrashAlt /> Clear
                                            </Button>}
                                    </div>

                                    {images?.length > 0 &&
                                        <ImageList cols={6}>
                                            {images?.map((item, index) => (
                                                <ImageListItem key={index} style={{ border: '2px solid #2e2d3c' }}>
                                                    <img
                                                        srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                                        // src={`${URL.createObjectURL(item)}?w=164&h=164&fit=crop&auto=format`}
                                                        src={URL.createObjectURL(item)}
                                                        alt={`image-${index}`}
                                                        loading="lazy" className='object-fit-contain'
                                                    />
                                                </ImageListItem>
                                            ))}
                                        </ImageList>
                                    }
                                </div>
                            </div>

                        </Form.Group>

                        <hr></hr>
                        <div className='btn-d d-flex justify-content-center'>
                            <Button type='button' className='text-white w-20' variant='contained'
                                onClick={onUpdate}
                                style={{ backgroundColor: '#0C0833', borderRadius: '1' }} >
                                Add Image
                            </Button>
                        </div>

                    </div>
                </section>

            </div >
        </>
    )
}

export default PortfolioUpdate