import { Button } from 'react-bootstrap';
import { useState, useEffect } from 'react'
import TextEditor from '../../Editor/TextEditor';
import Form from 'react-bootstrap/Form';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from "react-helmet";
import { useForm } from 'react-hook-form';
import { Axios } from '../../components/common/Axios';
import { notify, notifyErr } from '../../components/common/norify';

const ContentMarketing = () => {

  const [pending, setPending] = useState(false)
  const [id, setId] = useState('');
  const [updatedContent, setUpdatedContent] = useState('');
  const [updatedDescription, setUpdatedDescription] = useState('');
  const [formData, setFormData] = useState({
    title: '',
    heading: '',
    content: '',
    cta: ''
  })

  const getData = async () => {
    try {
      const contentMarketingData = await Axios.get(`/api/section/seo/content-marketing`);
      let contentMarketing = contentMarketingData.data.data;
      if (contentMarketing) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          ['title']: contentMarketing.title,
          ['heading']: contentMarketing.heading,
          ['content']: contentMarketing.content,
          ['cta']: contentMarketing.cta,
        }))
        setId(contentMarketing._id);
        setUpdatedContent(contentMarketing.content);
        setUpdatedDescription(contentMarketing.description);
      }

    } catch (error) {
      // Handle errors
      console.error('Error:', error);
    } finally {
      setPending(false)
    }
  }

  useEffect(() => {
    getData();
  }, []);


  const handleInputChange = (fieldName, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
  };


  useEffect(() => {
    handleInputChange('content', updatedContent);
    handleInputChange('description', updatedDescription);
  }, [updatedContent, updatedDescription])



  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = async () => {
    setPending(true);

    try {
      const response = await Axios.put(`/api/section/seo/content-marketing/${id}`, formData,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        });
      if (response.status === 200 || 201) {
        notify('Update Successfully');
        setTimeout(() => {
          getData();
        }, 1500);
      }
    } catch (error) {
      notifyErr('Something went wrong')
      console.error(error);
    }

  };

  return (
    <>
      <div className='profile-page'>
        <Helmet>
          <title>Content Marketing</title>
        </Helmet>
        <section className="wrapper _profile">
          <div className="row">
            <ToastContainer />
            <div className="col-lg-12">
              <span className='p-3 fw-bold'>Content Marketing</span>
            </div>
          </div>
        </section>
        <section classNAme="card  cardSectionBorder mt-3">
          <div className='m-2'>
            <Form className='row m-0' onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                <Form.Label>Title</Form.Label>
                <Form.Control type="text" placeholder="Heading" id="heading" value={formData.title}
                  {...register("title", {
                    required: false,
                    onChange: (e) => handleInputChange('title', e.target.value)
                  })}
                />

                {errors.title && (
                  <div className='errMsg text-danger'>title is required</div>
                )}

              </Form.Group>

              <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                <Form.Label>Heading</Form.Label>
                <Form.Control type="text" placeholder="Heading" id="heading" value={formData.heading}
                  {...register("Heading", {
                    required: false,
                    onChange: (e) => handleInputChange('heading', e.target.value)
                  })}
                />

                {errors.Heading && (
                  <div className='errMsg text-danger'>Heading is required</div>
                )}

              </Form.Group>

              <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-6 col-6">
                <Form.Label>CTA</Form.Label>
                <Form.Control type="text" placeholder='CTA' value={formData.cta}
                  {...register("CTA", {
                    required: false,
                    onChange: (e) => handleInputChange('cta', e.target.value)
                  })}
                />

                {errors.CTA && (
                  <div className='errMsg text-danger'>CTA is required</div>
                )}

              </Form.Group>

              <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                <Form.Label>Content</Form.Label>
                <TextEditor data={updatedContent} setUpdatedContent={setUpdatedContent} />

              </Form.Group>


              <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                <Form.Label>Description</Form.Label>

                <TextEditor data={updatedDescription} setUpdatedContent={setUpdatedDescription} />

              </Form.Group>

              <hr></hr>
              <div className='btn-d d-flex justify-content-center'>
                <Button type='submit' className=' Add_btn_ text-white' variant='contained'>
                  Update
                </Button>
              </div>
            </Form>
          </div>
        </section>
      </div>
    </>
  )
}

export default ContentMarketing