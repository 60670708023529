import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Datatables from '../../DataTable/Datatables';
import { Axios } from '../../components/common/Axios';
import { FaTrashAlt, FaEdit } from 'react-icons/fa';
import Swal from 'sweetalert2';


const Portfolio = () => {

  const apiUrl = process.env.REACT_APP_API_URL;

  const [data, setData] = useState([]);

  const getData = async () => {
    try {

      const portfolioData = await Axios.get('api/portfolio');
      let portfolio = portfolioData.data.data;
      setData(portfolio);

    } catch (error) {
      // Handle errors
      console.error('Error:', error);
    }
  }

  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  }, []);


  const tableData = {
    columns: [
      {
        name: <th className='fs-6 ' >#</th>,
        cell: (row, index) => index + 1,
        width: '150px',
      },
      {
        name: <th className='fs-6 '>Name</th>,
        selector: (row) => row.name,
        width: '200px',
      },
      {
        name: <th className='fs-6 '>Subtitle</th>,
        selector: (row) => row.subtitle,
        width: '250px',
      },
      {
        name: <th className='fs-6 '>Content</th>,
        selector: (row) => row.content,
        width: '250px',
      },
      {
        name: <th className='fs-6 '>Description</th>,
        selector: (row) => row.description,
        width: '300px',
      },

      {
        name: <th className='fs-6'>Image</th>,
        selector: (row) => (
          <img width="60px" height="60px" className='p-2' src={`${apiUrl}/static/assets/${row.image?.[0]}`} alt="Portfolio Image" />
        ),
        width: '200px',
      },

      // 

      // {
      //   name: <th className='fs-6 '>status</th>,
      //   selector: (row) => row.cta',
      //   width: '200px',
      // },
      {
        name: <th className='fs-6'>Action</th>,
        selector: (row) => (
          <div className='d-flex' style={{ gap: '10px' }}>
            <Link to={`/portfolio-update/${row._id}`}>
              <Button className='Add_btn_ text-white' variant='contained' style={{ float: 'right', backgroundColor: '#0C0833' }}>
                <FaEdit />
              </Button>
            </Link>
            {/* /${row._id} */}
            <Button className='_delete_btn_ bg-danger text-white' onClick={() => confirmDelete(row._id)} variant='contained' style={{ float: 'right', backgroundColor: '#3e3d52' }}><FaTrashAlt /></Button>
          </div>
        ),
        right: true,


      },
    ],
  };


  // functionality

  const handleDelete = async (id) => {
    try {
      const response = await Axios.delete(`api/portfolio/${id}`);
      if (response.status === 200) {
        setTimeout(() => {
          getData();
        }, 2000);
      }
      Swal.fire({
        title: 'Deleted!',
        text: 'Your data has been deleted.',
        icon: 'success',
        confirmButtonColor: '#0C0833',
      });
    } catch (error) {
      console.error(error);

      Swal.fire({
        title: 'Error',
        text: 'An error occurred while deleting the data.',
        icon: 'error',
      });
    }
  };



  const confirmDelete = (id) => {
    Swal.fire({
      title: 'Are you sure to Delete?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0C0833',
      cancelButtonColor: 'grey',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {

      if (result.value) {
        console.log(result)
        handleDelete(id);
      }
    });
  };





  return (
    <>
      <div className='portfolio-page'>
        <Helmet>
          <title>Portfolio</title>
        </Helmet>
        <section>
          <ToastContainer />
          <section className='portfolio-section'>
            <div className="d-flex justify-content-between align-items-center pt-7 pb-2 m-0">
              <div className="">
                <span className='fw-bold'>Portfolio</span>
              </div>
              <div className="">
                <Link to="/portfolio-add">
                  <Button className='Add_btn_ text-white' variant='contained' style={{ float: 'right', backgroundColor: '#0C0833', borderRadius: '1' }}  >
                    Add Portfolio
                  </Button>
                </Link>
              </div>
            </div>
          </section>
        </section>
        <section className=' mt-2'>
          <Card >
            <div className="main ">
              <Datatables data={data} columns={tableData.columns} />
            </div>
          </Card>

        </section>
      </div>
    </>
  )
}

export default Portfolio