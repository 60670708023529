import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';
import Form from 'react-bootstrap/Form';
import { ToastContainer, toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import TextEditor from '../../Editor/TextEditor';
import { Axios } from '../../components/common/Axios';
import { notify, notifyErr } from '../../components/common/norify';


const BlogUpdate = () => {

  const apiUrl = process.env.REACT_APP_API_URL;

  const { id } = useParams();
  const [pending, setPending] = useState(false)
  const [image, setImage] = useState('')
  const [thumbnail, setThumbnail] = useState('')
  const [updatedContent, setUpdatedContent] = useState('');
  const [updatedDescription, setUpdatedDescription] = useState('');
  const [formData, setFormData] = useState({
    title: '',
    heading: '',
    image: '',
    thumbnail: '',
    author: '',
    content: '',
    description: '',
    seo_title: '',
    canonical: '',
    robot: '',
    seo_script: '',
    feature_image: '',
    seo_description: '',
  })

  const getData = async () => {
    try {
      const blogData = await Axios.get(`/api/blog/${id}`);
      let blog = blogData.data;
      if (blog) {
        setFormData({
          title: blog.title,
          heading: blog.heading,
          author: blog.author,
          content: blog.content,
          description: blog.description,
          seo_title: blog.seo_title,
          canonical: blog.canonical,
          robot: blog.robot,
          seo_script: blog.seo_script,
          feature_image: blog.feature_image,
          seo_description: blog.seo_description,
        })
        setImage(blog.image);
        setThumbnail(blog.thumbnail);
        setUpdatedContent(blog.content);
        setUpdatedDescription(blog.description);
      }
    } catch (error) {
      // Handle errors
      console.error('Error:', error);
    } finally {
      setPending(false)
    }
  }

  useEffect(() => {
    getData();
  }, [id]);

  const handleClose = () => {
    setFormData({
      title: '',
      heading: '',
      image: '',
      thumbnail: '',
      author: '',
      content: '',
      description: '',
      seo_title: '',
      canonical: '',
      robot: '',
      seo_script: '',
      feature_image: '',
      seo_description: '',
    });
    reset({
      title: '',
      heading: '',
      image: '',
      thumbnail: '',
      author: '',
      content: '',
      description: '',
      seo_title: '',
      canonical: '',
      robot: '',
      seo_script: '',
      feature_image: '',
      seo_description: '',
    })
  }

  useEffect(() => {
    handleInputChange('content', updatedContent);
    handleInputChange('description', updatedDescription);
  }, [updatedContent, updatedDescription])



  const handleInputChange = (fieldName, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
  };


  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const onSubmit = async () => {
    try {
      const response = await Axios.put(`/api/blog/${id}`, formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      if (response.status === 200 || 201) {
        notify('blog updated Successfully');
        handleClose();
        setTimeout(() => {
          getData();
        }, 1500);
      }
    } catch (error) {
      notifyErr('Something went wrong')
      console.error(error);
    }
  }


  return (
    <>
      <div className='profile-page'>
        <Helmet>
          <title>Update Blog</title>
        </Helmet>
        <section className="wrapper _blogs">
          <div className="row">
            <div className="col-lg-6">
              <span className='p-3 fw-bold'>Update Blog</span>
            </div>

          </div>
        </section>
        <section className='card mt-2'>
          <div className='blog-d' >
            <ToastContainer />
            <Form className='row m-2' onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                <Form.Label>Title</Form.Label>
                <Form.Control type="text" placeholder="Title" id="title" value={formData.title}
                  {...register("Title", {
                    required: false,
                    onChange: (e) => handleInputChange('title', e.target.value)
                  })}
                />

                {errors.Title && (
                  <div className='errMsg text-danger'>Title is required</div>
                )}

              </Form.Group>

              <Form.Group
                className="mb-3"

              >

                <div className='row'>
                  <div className='col-lg-6'>
                    <Form.Label>Image</Form.Label>
                    <Form.Control type="file" name="file"
                      {...register("Image", {
                        required: false,
                        onChange: (e) => handleInputChange('image', e.target.files[0])
                      })}
                    />

                    {errors.Image && (
                      <div className='errMsg text-danger'>Please Select Image</div>
                    )}

                  </div>

                  <div className='col-lg-6 text-center mb-3 mt-3'>
                    <Form.Label>Image Preview</Form.Label>
                    <div className='p-3'>

                      {!formData.image ?
                        <img src={`${apiUrl}/static/assets/${image}`} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                        :
                        <img src={URL.createObjectURL(formData.image)} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                      }
                    </div>

                  </div>
                </div>

              </Form.Group>

              <Form.Group
                className="mb-3"

              >

                <div className='row'>
                  <div className='col-lg-6'>
                    <Form.Label>Thumbnail Image</Form.Label>
                    <Form.Control type="file" name="file"
                      {...register("thumbnail", {
                        required: false,
                        onChange: (e) => handleInputChange('thumbnail', e.target.files[0])
                      })}
                    />

                    {errors.thumbnail && (
                      <div className='errMsg text-danger'>Please Select Thumbnail Image</div>
                    )}

                  </div>

                  <div className='col-lg-6 text-center mb-3 mt-3'>
                    <Form.Label>Thumbnail Image Preview</Form.Label>
                    <div className='p-3'>

                      {!formData.thumbnail ?
                        <img src={`${apiUrl}/static/assets/${thumbnail}`} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                        :
                        <img src={URL.createObjectURL(formData.thumbnail)} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                      }
                    </div>

                  </div>
                </div>

              </Form.Group>

              <Form.Group className="mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                <Form.Label>Author</Form.Label>
                <Form.Control type="text" id="authore" value={formData.author}
                  {...register("Author", {
                    required: false,
                    onChange: (e) => handleInputChange('author', e.target.value)
                  })}
                />

                {errors.Author && (
                  <div className='errMsg text-danger'>Please Enter Author Name</div>
                )}

              </Form.Group >

              <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                <Form.Label>Content</Form.Label>
                <TextEditor data={updatedContent} setUpdatedContent={setUpdatedContent} />
              </Form.Group>

              <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                <Form.Label>Description</Form.Label>
                <TextEditor data={updatedDescription} setUpdatedContent={setUpdatedDescription} />
              </Form.Group>

              <div className="col-lg-12">
                <span className=' fw-bold'>SEO Section</span>
              </div>

              <Form.Group className="mt-2 mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                <Form.Label>SEO Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="SEO Title"
                  value={formData.seo_title}
                  {...register("Seo_title", {
                    required: false,
                    onChange: (e) => handleInputChange('seo_title', e.target.value)
                  })}
                />

                {errors.Seo_title && (
                  <div className='errMsg text-danger'>SEO title is required</div>
                )}
              </Form.Group>

              <Form.Group className="mt-2 mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                <Form.Label>Canonical</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Canonical"
                  value={formData.canonical}
                  onChange={(e) => handleInputChange('canonical', e.target.value)}

                />

              </Form.Group>

              <Form.Group className="mt-2 mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                <Form.Label htmlFor="Robot">Robot</Form.Label>
                <Form.Select
                  id="Robot"
                  aria-label="Default select example"
                  value={formData.robot}
                  onChange={(e) => handleInputChange('robot', e.target.value)}
                >
                  <option value="" >Select</option>
                  <option value="index,follow" >Index,Follow</option>
                  <option value="index,no-follow">Index,No-Follow</option>
                  <option value="no-index,follow">No-Index,Follow</option>
                  <option value="no-index,no-follow">No-Index,No-Follow</option>
                </Form.Select>

              </Form.Group>

              <Form.Group className="mt-2 mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                <Form.Label>Script</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Script"
                  value={formData.seo_script}
                  onChange={(e) => handleInputChange('script', e.target.value)}

                />

              </Form.Group>

              <Form.Group
                className="mt-2 mb-3 col-lg-6 col-md-12 col-sm-12 col-12"
              >

                <Form.Label>Feature Image</Form.Label>
                <Form.Control type="url" name="file" value={formData.feature_image}
                  onChange={(e) => handleInputChange('feature_image', e.target.value)} />


              </Form.Group>

              <Form.Group className="mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                <Form.Label>SEO Description</Form.Label>
                <Form.Control as="textarea" placeholder="SEO Description"
                  value={formData.seo_description}
                  onChange={(e) => handleInputChange('seo_description', e.target.value)} />

              </Form.Group>

              <div className='btn-d d-flex justify-content-center'>
                <Button type='submit' className='text-white' variant='contained'>
                  Update Blog
                </Button>
              </div>
            </Form>
          </div>

        </section>

      </div>
    </>
  )
}

export default BlogUpdate