import React, { useState } from 'react';
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, Input, Container } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

const LoginForm = () => {
  
  const apiUrl = process.env.REACT_APP_API_URL;

  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [authDetails, setAuthDetails] = useState([]);
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setFEmail('');
    reset({
      fEmail: ""
    });
  }


  const [fEmail, setFEmail] = useState('');

  const notify = () => toast.info('Successfully Login', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
  const loginError = (err) => toast.error(`${err}`, {
    position: "top-center",
    autoClose: 5023,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
  const notify1 = () => toast.info('Successfully Reset Password', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });




  const { register, handleSubmit: handleSubmit1, reset, formState: { errors: errors } } = useForm();
  const { register: register1, handleSubmit, formState: { errors: errors1 } } = useForm();
  const onSubmit = () => {
    axios.post(`${apiUrl}/api/admin/login`, { email: email, password: password }, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    }).then((response) => {
      if (response.status === 200) {
        setAuthDetails(response.data);
        const accessToken = response.data.accessToken;
        localStorage.setItem("auth", accessToken);
        notify();
        navigate('/');
        window.location.reload(false);
      }

    }).catch((err) => {
      loginError('Something went wrong!');
      console.log(err);
    })

  }

  const onforget = () => {
    axios.post(`${apiUrl}/api/admin/forgot-password`, {
      email: fEmail,
    }, {
      method: 'POST',
      
    })
      .then((response) => {
        if (response.status === 200) {
          handleClose();
          notify1();
         

        }
      })
      .catch((err) => {
        console.log(err);
      })
  }

  return (
    <>
      <div>
        <ToastContainer />
        <form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            <TextField name="email" value={email} autoComplete='off'
              placeholder='email'
              {...register1("Email", {
                required: true,
                onChange: (e) => setEmail(e.target.value)
              })}
            />

            {errors1.Email && (
              <div className='errMsg text-danger'>Email is Required</div>
            )}

            <TextField
              name="password"
              placeholder='password'
              type={showPassword ? 'text' : 'password'}
              value={password} autoComplete='new-password'

              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      {/* <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} /> */}
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...register1("Password", {
                required: true,
                onChange: (e) => setPassword(e.target.value)
              })}
            />

            {errors1.Password && (
              <div className='errMsg text-danger'>Password is Required</div>
            )}

          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="end" sx={{ my: 2 }}>
            {/* <Checkbox name="remember" label="Remember me" /> */}
            <Link variant="subtitle2" underline="hover" style={{ cursor: 'pointer' }} onClick={handleShow}>
              Forgot password?
            </Link>
          </Stack>

          <LoadingButton fullWidth size="large" type="submit" variant="contained" style={{ backgroundColor: '#0C0833' }} >
            Login
          </LoadingButton>
        </form>
      </div>



      <Modal show={show} onHide={handleClose} className='' style={{ zIndex: '99999' }}>
        <Modal.Header closeButton>

          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>
        <Form className='row m-2' onSubmit={handleSubmit1(onforget)} autoComplete="off">
          <Modal.Body>

            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter Your Email"
                value={fEmail}
                {...register("Email", {
                  required: "Email is required",
                })}
                onChange={(e) => setFEmail(e.target.value)}
              />
              {errors.Email && (
                <div className='errMsg text-danger'>{errors.Email.message}</div>
              )}
            </Form.Group>




          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button type='submit' className='text-white' variant='contained' style={{ backgroundColor: '#0C0833' }} >
              Reset
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

    </>
  )
}

export default LoginForm