import { Button } from 'react-bootstrap';
import { useState, useEffect } from 'react'
import TextEditor from '../../Editor/TextEditor';
import Form from 'react-bootstrap/Form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from "react-helmet";
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Axios } from '../../components/common/Axios';
import { notify, notifyErr } from '../../components/common/norify';
import SubContentModel from '../../components/common/SubContentModel';

const Process = () => {

  const [pending, setPending] = useState(true)
  const [id, setId] = useState('');
  const [updatedHeading, setUpdatedHeading] = useState('')
  const [updatedContent, setUpdatedContent] = useState('');
  const [updatedDescription, setUpdatedDescription] = useState('');


  const getData = async () => {
    try {
      const processData = await Axios.get(`/api/home/web-progress`);
      let process = processData.data.data;
      if (process) {
        setId(process._id);
        setUpdatedHeading(process.heading)
        setUpdatedContent(process.content)
        setUpdatedDescription(process.description)
      }

    } catch (error) {
      // Handle errors
      console.error('Error:', error);
    } finally {
      setPending(false)
    }
  }

  useEffect(() => {
    getData();
  }, []);


  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = async () => {
    setPending(true);

    try {
      const response = await Axios.put(`/api/home/web-progress/${id}`, {
        heading: updatedHeading,
        content: updatedContent,
        description: updatedDescription
      },
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        });
      if (response.status === 200 || 201) {
        notify('Update Successfully');
        setTimeout(() => {
          getData();
        }, 1500);
      }
    } catch (error) {
      notifyErr('Something went wrong')
      console.error(error);
    } finally {
      setPending(false)
    }

  };
  return (
    <>
      <div className='profile-page'>
        <Helmet>
          <title>Process</title>
        </Helmet>
        <section className="wrapper _profile">
          <div className="row">
            <ToastContainer />
            <div className="col-lg-12">
              <span className='p-3 fw-bold'>Process</span>
            </div>
          </div>
        </section>
        <section classNAme="card  cardSectionBorder mt-3">
          <div className='m-2'>
            <Form className='row m-0' onSubmit={handleSubmit(onSubmit)}>



              {/* <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                <Form.Label>Title</Form.Label>
                <Form.Control type="text" placeholder="Heading" id="heading" value={formData.title}
                  {...register("title", {
                    required: false,
                    onChange: (e) => handleInputChange('title', e.target.value)
                  })}
                />

                {errors.title && (
                  <div className='errMsg text-danger'>title is required</div>
                )}

              </Form.Group> */}

              <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                <Form.Label>Heading</Form.Label>
                <TextEditor data={updatedHeading} setUpdatedContent={setUpdatedHeading} />
              </Form.Group>

              <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                <Form.Label>Content</Form.Label>
                <TextEditor data={updatedContent} setUpdatedContent={setUpdatedContent} />
              </Form.Group>

              <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                <Form.Label>Description</Form.Label>
                <TextEditor data={updatedDescription} setUpdatedContent={setUpdatedDescription} />
              </Form.Group>

              <hr></hr>
              <div className='btn-d d-flex justify-content-center'>
                <Button type='submit' className=' Add_btn_ text-white' variant='contained'>
                  Update
                </Button>
              </div>
            </Form>
          </div>
        </section>

        {/* <section>
          <SubContentModel _id={id} type={1} />
        </section> */}

      </div>
    </>
  )
}

export default Process