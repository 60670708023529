import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Datatables from '../../DataTable/Datatables';
import { FaTrashAlt, FaEdit } from 'react-icons/fa';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import { Axios } from '../../components/common/Axios';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import Swal from "sweetalert2";

const Blogs = () => {

    const apiUrl = process.env.REACT_APP_API_URL;

    const [data, setData] = useState([]);
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [pending, setPending] = useState(true);


    const getData = async () => {
        try {
            const blogData = await Axios.get(`/api/blog`);
            let blog = blogData.data.data;
            setData(blog);

        } catch (error) {
            // Handle errors
            console.error('Error:', error);
        } finally {
            setPending(false)
        }
    }

    useEffect(() => {
        getData();
    }, []);

    const tableData = {
        columns: [
            {
                name: <th className='fs-6 ' >#</th>,
                cell: (row, index) => index + 1,
                width: '100px',
            },
            {
                name: <th className='fs-6 '>Title</th>,
                selector: (row) => row.title,
                width: '250px',
            },
            {
                name: <th className='fs-6 '>Author</th>,
                selector: (row) => row.author,
                width: '250px',
            },
            {
                name: <th className='fs-6'>Thumbnail</th>,
                selector: (row) => (
                    <img width="60px" height="60px" className='p-2' src={`${apiUrl}/static/assets/${row.thumbnail}`} alt="Thumbnail Image" />
                ),
                width: '280px',
            },
            {
                name: <th className='fs-6'>Image</th>,
                selector: (row) => (
                    <img width="60px" height="60px" className='p-2' src={`${apiUrl}/static/assets/${row.image}`} alt="blog Image" />
                ),
                width: '280px',
            },
            {
                name: <th className='fs-6'>Action</th>,
                selector: (row) => (
                    <div className='d-flex' style={{ gap: '10px' }}>
                        <Link to={`/blog-update/${row._id}`}>
                            <Button className='Add_btn_ text-white' variant='contained' style={{ float: 'right', backgroundColor: '#0C0833' }}>
                                <FaEdit />
                            </Button>
                        </Link>
                        <Button className='_delete_btn_ text-white bg-danger' variant='contained'
                            onClick={() => confirmDelete(row._id)}
                        ><FaTrashAlt /></Button>
                    </div>
                ),
                right: true,
               

            },
        ],
    };



    const handleDelete = async (id) => {
        try {
            const response = await Axios.delete(`/api/blog/${id}`);
            if (response.status === 200) {
                setTimeout(() => {
                    getData();
                }, 2000);
            }
            Swal.fire({
                title: 'Deleted!',
                text: 'Your data has been deleted.',
                icon: 'success',
                confirmButtonColor: '#0C0833',
            });
        } catch (error) {
            console.error(error);

            Swal.fire({
                title: 'Error',
                text: 'An error occurred while deleting the data.',
                icon: 'error',
            });
        }
    };

    const confirmDelete = (id) => {
        Swal.fire({
            title: 'Are you sure to Delete?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#0C0833',
            cancelButtonColor: 'grey',
            confirmButtonText: 'Yes, delete it!',
        }).then((result) => {

            if (result.value) {
                console.log(result)
                handleDelete(id);
            }
        });
    };

    return (
        <>
            <div className='profile-page'>
                <Helmet>
                    <title>Blogs</title>
                </Helmet>

                <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
                    <ToastContainer />
                    <section className="wrapper _blogs">
                        <div className="row">
                            <div className="col-lg-6">
                                <span className='p-3 fw-bold'>Blogs</span>
                            </div>

                            <div className='col-lg-6 pb-2 btn-d d-flex justify-content-end'>
                                <Link to="/blog-add">
                                    <Button className='Add_btn_ text-white' variant='contained' style={{ float: 'right' }} >
                                        Add Blog
                                    </Button>
                                </Link>

                            </div>
                        </div>
                    </section>

                </section>

                <section className=' mt-2'>
                    <Card >
                        <div className="main ">
                            <Datatables data={data} columns={tableData.columns} />
                        </div>
                    </Card>

                </section>

            </div>
        </>
    )
}

export default Blogs