import { Button } from 'react-bootstrap';
import { useState, useEffect } from 'react'
import TextEditor from '../../Editor/TextEditor';
import Form from 'react-bootstrap/Form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from "react-helmet";
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Axios } from '../../components/common/Axios';
import { notify, notifyErr } from '../../components/common/norify';
import SubContentModel from '../../components/common/SubContentModel';

const ServiceCards = () => {
    // /api/home/service-card

    const [pending, setPending] = useState(false)
    const [id, setId] = useState('');
    const [updatedContent, setUpdatedContent] = useState('');
    const [services, setServices] = useState([]);
    const [formData, setFormData] = useState({
        title: '',
        heading: '',
        content: '',
    })

    const getData = async () => {

        try {
            const response = await Axios.get('api/header');
            // Handle the response
            let resData = response.data.header;
            if (resData) {
                const mainService = resData?.filter((page) =>
                    (page.subService && page.subService.length > 0) &&
                    (page.slug != '' && page.slug != '/')
                ).map((item) => item);
                const subService = resData?.flatMap(service => (
                    service.subService?.filter(item => item.name !== '')
                )).filter(Boolean);
                let serviceList = mainService.concat(subService);
                console.log(serviceList)
                setServices(serviceList);
            }

        } catch (error) {
            // Handle errors
            console.error('Error:', error);
        }

        try {
            const serviceCardData = await Axios.get(`/api/home/service-card`);
            let serviceCard = serviceCardData.data.serviceDetails;
            if (serviceCard) {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    ['title']: serviceCard.title,
                    ['content']: serviceCard.content,
                    ['heading']: serviceCard.heading,


                }))
                setId(serviceCard._id);
                setUpdatedContent(serviceCard.content);
            }

        } catch (error) {
            // Handle errors
            console.error('Error:', error);
        } finally {
            setPending(false)
        }
    }

    useEffect(() => {
        getData();
    }, []);


    const handleInputChange = (fieldName, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [fieldName]: value,
        }));
    };

    useEffect(() => {
        handleInputChange('content', updatedContent);
    }, [updatedContent])

    // useEffect(() => {
    //     handleInputChange('content', updatedContent)
    //     handleInputChange('description', updatedDescription)
    // }, [updatedContent, updatedDescription])



    // sub content 



    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = async () => {
        setPending(true);

        try {
            const response = await Axios.put(`/api/home/service-card/${id}`, formData,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                });
            if (response.status === 200 || 201) {
                notify('Update Successfully');
                setTimeout(() => {
                    getData();
                }, 1500);
            }
        } catch (error) {
            notifyErr('Something went wrong')
            console.error(error);
        }

    };
    return (
        <>
            <div className='profile-page'>
                <Helmet>
                    <title>Service Card</title>
                </Helmet>
                <section className="wrapper _profile">
                    <div className="row">
                        <ToastContainer />
                        <div className="col-lg-12">
                            <span className='p-3 fw-bold'>Service Card</span>
                        </div>
                    </div>
                </section>
                <section classNAme="card  cardSectionBorder mt-3">
                    <div className='m-2'>
                        <Form className='row m-0' onSubmit={handleSubmit(onSubmit)}>



                            <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                                <Form.Label>Title</Form.Label>
                                <Form.Control type="text" placeholder="Heading" id="heading" value={formData.title}
                                    {...register("title", {
                                        required: false,
                                        onChange: (e) => handleInputChange('title', e.target.value)
                                    })}
                                />

                                {errors.title && (
                                    <div className='errMsg text-danger'>title is required</div>
                                )}

                            </Form.Group>

                            <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                                <Form.Label>Heading</Form.Label>
                                <Form.Control type="text" placeholder="Heading" id="heading" value={formData.heading}
                                    {...register("Heading", {
                                        required: false,
                                        onChange: (e) => handleInputChange('heading', e.target.value)
                                    })}
                                />

                                {errors.Heading && (
                                    <div className='errMsg text-danger'>Heading is required</div>
                                )}

                            </Form.Group>


                            <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                                <Form.Label>Content</Form.Label>
                                <TextEditor data={updatedContent} setUpdatedContent={setUpdatedContent} />
                             

                            </Form.Group>

                            <hr></hr>
                            <div className='btn-d d-flex justify-content-center'>
                                <Button type='submit' className=' Add_btn_ text-white' variant='contained'>
                                    Update
                                </Button>
                            </div>
                        </Form>
                    </div>
                </section>


                <section>
                    <SubContentModel _id={id} type={6} services={services} />
                </section>

            </div>
        </>
    )
}

export default ServiceCards