import { Button } from 'react-bootstrap';
import { useState, useEffect } from 'react'
import TextEditor from '../../Editor/TextEditor';
import Form from 'react-bootstrap/Form';
import 'react-toastify/dist/ReactToastify.css';
import { useForm, Controller } from 'react-hook-form';
import { Axios } from '../../components/common/Axios';
import { notify, notifyErr } from '../../components/common/norify';

const ThirdSection = ({ section, parent }) => {

    const [id, setId] = useState('');
    const [image, setImage] = useState('')
    const [updatedContent, setUpdatedContent] = useState('');
    const [updatedDescription, setUpdatedDescription] = useState('');
    const [formData, setFormData] = useState({
        title: '',
        heading: '',
        content: '',
        description: ''
    })

    const getData = async () => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            ['title']: section.title,
            ['heading']: section.heading,
            ['content']: section.content,
            ['description']: section.description,
        }))
        setImage(section.image);
        setUpdatedContent(section.content);
        setUpdatedDescription(section.description);
    }

    useEffect(() => {
        getData()
        setId(parent)
    }, [section, parent]);


    const handleInputChange = (fieldName, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [fieldName]: value,
        }));
    };

    useEffect(() => {
        handleInputChange('content', updatedContent);
        handleInputChange('description', updatedDescription);
    }, [updatedContent, updatedDescription])


    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = async () => {
        try {
            const response = await Axios.put(`/api/section/sub-service/section/${id}/3`, formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            if (response.status === 200 || 201) {
                notify('Sub Service updated Successfully');
                setTimeout(() => {
                    getData()
                }, 1500);
            }
        } catch (error) {
            notifyErr('Something went wrong')
            console.error(error);
        }
    };
    return (
        <>
            <div className='m-2'>
                <Form className='row m-0' onSubmit={handleSubmit(onSubmit)}>



                    <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                        <Form.Label>Title</Form.Label>
                        <Form.Control type="text" placeholder="Heading" id="heading" value={formData.title}
                            {...register("title", {
                                required: false,
                                onChange: (e) => handleInputChange('title', e.target.value)
                            })}
                        />

                        {errors.title && (
                            <div className='errMsg text-danger'>title is required</div>
                        )}

                    </Form.Group>

                    <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                        <Form.Label>Heading</Form.Label>
                        <Form.Control type="text" placeholder="Heading" id="heading" value={formData.heading}
                            {...register("Heading", {
                                required: false,
                                onChange: (e) => handleInputChange('heading', e.target.value)
                            })}
                        />

                        {errors.Heading && (
                            <div className='errMsg text-danger'>Heading is required</div>
                        )}

                    </Form.Group>


                    <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                        <Form.Label>Content</Form.Label>
                        <TextEditor data={updatedContent} setUpdatedContent={setUpdatedContent} />

                    </Form.Group>


                    <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                        <Form.Label>Description</Form.Label>
                        <TextEditor data={updatedDescription} setUpdatedContent={setUpdatedDescription} />

                    </Form.Group>
                    {/* <Form.Group
                        className="mb-3" >

                        <div className='row'>
                            <div className='col-lg-6'>
                                <Form.Label>Image</Form.Label>
                                <Form.Control type="file" id="file"
                                    {...register("Image", {
                                        required: false,
                                        onChange: (e) => handleInputChange('image', e.target.files[0])
                                    })}
                                />

                                {errors.Image && (
                                    <div className='errMsg text-danger'>Please Select Image</div>
                                )}

                            </div>

                            <div className='col-lg-6 text-center mb-3 mt-3'>
                                <Form.Label>Image Preview</Form.Label>
                                <div className='p-3'>

                                    {!formData.image ?

                                        <img src={`${apiUrl}/static/assets/${image}`} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                                        :
                                        <img src={URL.createObjectURL(formData.image)} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                                    }
                                </div>

                            </div>
                        </div>

                    </Form.Group> */}
                    <hr></hr>
                    <div className='btn-d d-flex justify-content-center'>
                        <Button type='submit' className=' Add_btn_ text-white' variant='contained'>
                            Update
                        </Button>
                    </div>
                </Form>
            </div>
        </>
    )
}

export default ThirdSection