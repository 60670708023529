import { AiFillDashboard, AiOutlineProfile } from 'react-icons/ai';
import {  FaHome, FaProjectDiagram, FaInfo, FaBloggerB } from 'react-icons/fa';
import { MdMarkEmailUnread, MdDeveloperMode, MdPolicy } from 'react-icons/md';
import { GrProjects } from "react-icons/gr";
import { TbSeo } from "react-icons/tb";
import { RiSeoLine } from "react-icons/ri";
import { ImProfile } from "react-icons/im";
import { TbRefreshAlert } from "react-icons/tb";
import { LuBookOpenCheck } from "react-icons/lu";


export const SidebarItems = [
    {
        title: "Dashboard",
        url: "/",
        icon: <AiFillDashboard />
    },
    {
        title: "Enquiry",
        url: "/enquiry",
        icon: <MdMarkEmailUnread />
    },
    {
        title: "Services",
        url: "/service",
        icon: <GrProjects />
    },
    {
        title: "Sub Services",
        url: "/sub-services",
        icon: <FaProjectDiagram />
    },
    {
        title: "SEO",
        url: "/seo",
        icon: <TbSeo />
    },
    {
        title: "Home Page",
        url: "/",
        icon: <FaHome />,
        submenu: [
            {
                title: "Service Cards",
                url: "/service-card",
                // icon: <AiFillDashboard />,
            },
            {
                title: "Values",
                url: "/values",
                // icon: <AiFillDashboard />,
            },
            {
                title: "How We Works",
                url: "/how-we-works",
                // icon: <AiFillDashboard />,
            },
            {
                title: "Why Choose Us",
                url: "/why-choose-us",
                // icon: <AiFillDashboard />,
            },
            {
                title: "Faqs",
                url: "/faqs",
                // icon: <AiFillDashboard />,
            }
        ]
    },
    {
        title: "SEO Page",
        url: "/",
        icon: <RiSeoLine />,
        submenu: [
            {
                title: "SEO Techniques",
                url: "/seo-techniques",
                // icon: <AiFillDashboard />,
            },
            {
                title: "Ranking",
                url: "/ranking",
                // icon: <AiFillDashboard />,
            },
            {
                title: "Optimization Strategies",
                url: "/optimization-strategies",
                // icon: <AiFillDashboard />,
            },
            {
                title: "Content Marketing",
                url: "/content-marketing",
                // icon: <AiFillDashboard />,
            },
            {
                title: "SEO Expertise",
                url: "/seo-expertise",
                // icon: <AiFillDashboard />,
            },
            {
                title: "SEO Pricing",
                url: "/seo-pricing",
                // icon: <AiFillDashboard />,
            }
        ]
    },
    {
        title: "Web development Page",
        url: "/",
        icon: <MdDeveloperMode />,
        submenu: [
            {
                title: "Technologies",
                url: "/technologies",
                // icon: <AiFillDashboard />,
            },
            {
                title: "Approaches",
                url: "/approaches",
                // icon: <AiFillDashboard />,
            },
            {
                title: "Web Expertise",
                url: "/web-expertise",
                // icon: <AiFillDashboard />,
            },

            {
                title: "Process",
                url: "/process",
                // icon: <AiFillDashboard />,
            }
        ]
    },
    // {
    //     title: "Web/App Development",
    //     url: "/web-development",
    //     icon: <MdMarkEmailUnread />,
    //     submenu: [
    //         {
    //             title: "Custom Website Development",
    //             url: "/custom-website-development",
    //             icon: <AiFillDashboard />,
    //         },
    //         {
    //             title: "E-commerce Solutions",
    //             url: "/e-commerce-solutions",
    //             icon: <AiFillDashboard />,
    //         },
    //         {
    //             title: "Mobile App Development",
    //             url: "/mobile-app-development",
    //             icon: <AiFillDashboard />,
    //         },
    //         {
    //             title: "Web Application Development",
    //             url: "/web-application-development",
    //             icon: <AiFillDashboard />,
    //         },
    //         {
    //             title: "Content Management Systems (CMS) Development",
    //             url: "/content-management-systems-development",
    //             icon: <AiFillDashboard />,
    //         },
    //         {
    //             title: "Web Services and Integration",
    //             url: "/web-services-and-integration",
    //             icon: <AiFillDashboard />,
    //         }
    //     ]
    // },
    // {
    //     title: " Services",
    //     url: "/sub-services",
    //     icon: <MdGroups2 />,
    //     submenu: [
    //         {
    //             title: " Web Design",
    //             url: "/sub-services",
    //             icon: <RiPsychotherapyFill />,
    //         },
    //         {
    //             title: "Web/App Development",
    //             url: "/e-commerce-solutions",
    //             icon: <RiPsychotherapyFill />,
    //         },
    //         {
    //             title: "Search Engine Optimization (SEO)",
    //             url: "/mobile-app-development",
    //             icon: <RiPsychotherapyFill />,
    //         },

    //     ]
    // },
    // {
    //     title: "Sub Services",
    //     url: "/sub-services",
    //     icon: <MdGroups2 />,
    //     submenu: [
    //         {
    //             title: "Sub Services",
    //             url: "/sub-services",
    //             icon: <RiPsychotherapyFill />,
    //         },
    //         {
    //             title: "Section 1",
    //             url: "/e-commerce-solutions",
    //             icon: <RiPsychotherapyFill />,
    //         },
    //         {
    //             title: "Section 2",
    //             url: "/mobile-app-development",
    //             icon: <RiPsychotherapyFill />,
    //         },
    //         {
    //             title: "Section 3",
    //             url: "/web-application-development",
    //             icon: <RiPsychotherapyFill />,
    //         },
    //         {
    //             title: "Section 4",
    //             url: "/web-application-development",
    //             icon: <RiPsychotherapyFill />,
    //         },

    //     ]
    // },
    // {
    //     title: "Search Engine Optimization (SEO)",
    //     url: "/enquiries",
    //     icon: <MdMarkEmailUnread />,
    //     submenu: [

    //         {
    //             title: "Online Reputation Management (ORM)",
    //             url: "/online-reputation-management",
    //             icon: <AiFillDashboard />,
    //         }
    //     ]
    // },
    {
        title: "Portfolio",
        url: "/portfolio",
        icon: <ImProfile />,
    },
    {
        title: "Testimonials",
        url: "/testimonials",
        icon: <AiOutlineProfile />,
    },
    {
        title: "About Us",
        url: "/about",
        icon: <FaInfo />
    },
    {
        title: "Blog",
        url: "/blog",
        icon: <FaBloggerB />,
    },
    {
        title: "Privacy Policy",
        url: "/privacy-policy",
        icon: <MdPolicy />
    },
    {
        title: "Term Conditions",
        url: "/term-conditions",
        icon: <MdPolicy />
    },
    {
        title: "Popup Model",
        url: "/popup-model",
        icon: <TbRefreshAlert />
    },
    {
        title: "Thankyou Page",
        url: "/thankyou",
        icon: <LuBookOpenCheck />
    },
]