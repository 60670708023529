import React, { useState, useEffect, Component } from 'react';
import Datatables from '../../DataTable/Datatables';
import { FaTrashAlt, FaEdit } from 'react-icons/fa';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Axios } from '../../components/common/Axios';
import Card from 'react-bootstrap/Card';
import Swal from "sweetalert2";
import { notify, notifyErr } from '../../components/common/norify';


const Service = () => {

  const apiUrl = process.env.REACT_APP_API_URL;

  const [data, setData] = useState([]);
  const [pending, setPending] = useState(true);
  const [id, setId] = useState('')

  const getData = async () => {
    try {
      const serviceData = await Axios.get('/api/service');
      let service = serviceData.data.data;
      setData(service);

    } catch (error) {
      // Handle errors
      console.error('Error:', error);
    } finally {
      setPending(false)
    }
  }

  useEffect(() => {
    getData();
  }, []);

  const tableData = {
    columns: [
      {
        name: <th className='fs-6 ' >#</th>,
        cell: (row, index) => index + 1,
        width: '100px',
      },
      {
        name: <th className='fs-6 '>Name</th>,
        selector: (row) => row.name,
        width: '200px',
      },
      {
        name: <th className='fs-6 '>Cta</th>,
        selector: (row) => row.cta,
        width: '200px',
      },
      {
        name: <th className='fs-6'>Heading</th>,
        selector: (row) => row.heading,
        width: '220px',
      },
      {
        name: <th className='fs-6'>Media</th>,
        selector: (row) => (
          <>
            {row.image && <img width="60px" height="60px" className='p-2' src={`${apiUrl}/static/assets/${row.image}`} alt="Service Image" />}
            {row.video && <video width="60px" height="60px" className='p-2' src={`${apiUrl}/static/assets/${row.video}`} alt="Service Image" />}
          </>
        ),
        width: '160px',
      },
      {
        name: <th className='fs-6'>Action</th>,
        selector: (row) => (
          <div className='d-flex' style={{ gap: '10px' }}>
            <Link to={`/service-update/${row._id}`}>
              <Button className='Add_btn_ text-white' variant='contained' style={{ float: 'right', backgroundColor: '#0C0833' }}>
                <FaEdit />
              </Button>
            </Link>

          </div>
        ),
        right: true,
        width: '180px',

      },
    ],
  };


  // const handleDelete = async (id) => {
  //   try {
  //     const response = await Axios.delete(`/api/service/${id}`);
  //     Swal.fire({
  //       title: 'Deleted!',
  //       text: 'Your data has been deleted.',
  //       icon: 'success',
  //       confirmButtonColor: '#0C0833',
  //     });
  //   } catch (error) {
  //     console.error(error);
  //     notifyErr('Something went wrong!')
  //     Swal.fire({
  //       title: 'Error',
  //       text: 'An error occurred while deleting the data.',
  //       icon: 'error',
  //     });
  //   }
  // };

  // const confirmDelete = (id) => {
  //   Swal.fire({
  //     title: 'Are you sure to Delete?',
  //     text: "You won't be able to revert this!",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#0C0833',
  //     cancelButtonColor: 'grey',
  //     confirmButtonText: 'Yes, delete it!',
  //   }).then((result) => {
  //     if (result.value) {
  //       handleDelete(id);
  //     }
  //   });
  // };


  return (
    <>
      <div className='service-page'>
        <Helmet>
          <title>Services</title>
        </Helmet>

        <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
          <ToastContainer />
          <section className="wrapper _blogs">
            <div className="row">
              <div className="col-lg-6">
                <span className='p-3 fw-bold'>Services</span>
              </div>

              <div className='col-lg-6 pb-2 btn-d d-flex justify-content-end'>
                {/* <Link to="/service-add">
                  <Button className='Add_btn_ text-white' variant='contained' style={{ float: 'right' }} >
                    Add Service
                  </Button>
                </Link> */}

              </div>
            </div>
          </section>

        </section>

        <section className=' mt-2'>
          <Card >
            <div className="main " >
              <Datatables data={data} columns={tableData.columns} />
            </div>
          </Card>

        </section>


      </div>
    </>
  )
}

export default Service