import { Button } from 'react-bootstrap';
import { useState, useEffect } from 'react'
import TextEditor from '../../Editor/TextEditor';
import Form from 'react-bootstrap/Form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from "react-helmet";
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Axios } from '../../components/common/Axios';
import { notify, notifyErr } from '../../components/common/norify';

const WebExpertise = () => {


  const [pending, setPending] = useState(true)
  const [id, setId] = useState('');
  const [updatedContent, setUpdatedContent] = useState('');
  const [formData, setFormData] = useState({
    // title: '',
    heading: '',
    content: '',
  })

  const getData = async () => {
    try {
      const technologiesData = await Axios.get(`/api/home/web-expertise`);
      let technologies = technologiesData.data.data;
      if (technologies) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          // ['title']: technologies.title,
          ['heading']: technologies.heading,
        }))
        setId(technologies._id);
        setUpdatedContent(technologies.content)
      }

    } catch (error) {
      // Handle errors
      console.error('Error:', error);
    } finally {
      setPending(false)
    }
  }

  useEffect(() => {
    getData();
  }, []);


  const handleInputChange = (fieldName, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
  };


  useEffect(() => {
    handleInputChange('content', updatedContent)
  }, [updatedContent])


  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = async () => {
    setPending(true);
    try {
      const response = await Axios.put(`/api/home/web-expertise/${id}`, formData,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        });
      if (response.status === 200 || 201) {
        notify('Update Successfully');
        setTimeout(() => {
          getData();
        }, 1500);
      }
    } catch (error) {
      notifyErr('Something went wrong')
      console.error(error);
    } finally {
      setPending(false)
    }

  };

  return (
    <>
      <div className='profile-page'>
        <Helmet>
          <title>SEO technologies</title>
        </Helmet>
        <section className="wrapper _profile">
          <div className="row">
            <ToastContainer />
            <div className="col-lg-12">
              <span className='p-3 fw-bold'>SEO technologies</span>
            </div>
          </div>
        </section>
        <section classNAme="card  cardSectionBorder mt-3">
          <div className='m-2'>
            <Form className='row m-0' onSubmit={handleSubmit(onSubmit)}>



              {/* <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                <Form.Label>Title</Form.Label>
                <Form.Control type="text" placeholder="Heading" id="heading" value={formData.title}
                  {...register("title", {
                    required: false,
                    onChange: (e) => handleInputChange('title', e.target.value)
                  })}
                />

                {errors.title && (
                  <div className='errMsg text-danger'>title is required</div>
                )}

              </Form.Group> */}

              <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                <Form.Label>Heading</Form.Label>
                <Form.Control type="text" placeholder="Heading" id="heading" value={formData.heading}
                  {...register("Heading", {
                    required: false,
                    onChange: (e) => handleInputChange('heading', e.target.value)
                  })}
                />

                {errors.Heading && (
                  <div className='errMsg text-danger'>Heading is required</div>
                )}

              </Form.Group>

              <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                <Form.Label>Content</Form.Label>
                <TextEditor data={updatedContent} setUpdatedContent={setUpdatedContent} />
              </Form.Group>

              {/* <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                <Form.Label>Description</Form.Label>
                <TextEditor data={updatedDescription} setUpdatedContent={setUpdatedDescription} />
              </Form.Group> */}

              <hr></hr>
              <div className='btn-d d-flex justify-content-center'>
                <Button type='submit' className=' Add_btn_ text-white' variant='contained'>
                  Update
                </Button>
              </div>
            </Form>
          </div>
        </section>
      </div>
    </>
  )
}

export default WebExpertise