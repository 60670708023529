import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';
import Form from 'react-bootstrap/Form';
import { ToastContainer, toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import TextEditor from '../../Editor/TextEditor';
import { Axios } from '../../components/common/Axios';

const ServiceUpdate = () => {

    const apiUrl = process.env.REACT_APP_API_URL;

    const { id } = useParams();
    const [name, setName] = useState('');
    const [cta, setCTA] = useState('');
    const [heading, setHeading] = useState('');
    const [slug, setSlug] = useState('');
    const [title, setTitle] = useState('');
    const [image, setImage] = useState('');
    const [updatedImage, setUpdatedImage] = useState('');
    const [updatedContent, setUpdatedContent] = useState('');
    const [updatedDescription, setUpdatedDescription] = useState('');
    const [video, setVideo] = useState('');
    const [updatedVideo, setUpdatedVideo] = useState('');
    const [seo_title, setSeo_title] = useState('');
    const [canonical, setCanonical] = useState('');
    const [robot, setRobot] = useState('Index,Follow');
    const [script, setScript] = useState('');
    const [feature_image, Setfeature_image] = useState('');
    const [seo_description, setSeo_description] = useState('');
    const [pending, setPending] = useState(true);

    const handleClose = () => {
        setName('');
        setCTA('');
        setTitle('');
        setHeading('');
        setSlug('');
        setUpdatedImage('');
        setVideo('');
        setUpdatedContent('');
        setUpdatedDescription('');
    }

    const getData = () => {
        Axios.get(`/api/service/${id}`)
            .then((response) => {
                const serviceData = response.data;
                console.log('blogData');
                if (serviceData) {
                    setName(serviceData.name);
                    setCTA(serviceData.cta);
                    setSlug(serviceData.slug);
                    setTitle(serviceData.title);
                    setHeading(serviceData.heading);
                    setImage(serviceData.image);
                    setVideo(serviceData.video);
                    setUpdatedContent(serviceData.content);
                    setUpdatedDescription(serviceData.description);
                    setSeo_title(serviceData.seo_title);
                    setCanonical(serviceData.canonical);
                    setRobot(serviceData.robot);
                    setScript(serviceData.script);
                    Setfeature_image(serviceData.feature_image);
                    setSeo_description(serviceData.seo_description);

                }
            })
            .catch((err) => {
                console.log(err);
            });

        // api/section/sub-service/section/
    };

    useEffect(() => {
        getData();
        window.scrollTo(0, 0);
    }, []);



    const updateServiceSuccess = () => toast.info("Service Updated Successfully", { position: "top-center", autoClose: 2000, });
    const updateServiceError = () => toast.error("Something went wrong", { position: "top-center", autoClose: 2000, });
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onUpdate = (serviceData) => {
        setPending(true);

        const formData = new FormData();
        // formData.append("file", updatedImage);

        Axios.put(`/api/service/${id}`, {
            name: name,
            cta: cta,
            slug: slug,
            title: title,
            heading: heading,
            image: updatedImage,
            video: updatedVideo,
            content: updatedContent,
            description: updatedDescription,
            seo_title: seo_title,
            canonical: canonical,
            robot: robot,
            script: script,
            feature_image: feature_image,
            seo_description: seo_description,
        }, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    handleClose();
                    updateServiceSuccess();
                    setTimeout(() => {
                        getData();
                    }, 1500);
                }
            })
            .catch((err) => {
                updateServiceError();
                console.error(err);
            });
    }
    return (
        <>
            <div className='profile-page'>
                <Helmet>
                    <title>Update Service</title>
                </Helmet>
                <section className="wrapper _blogs">
                    <div className="row">
                        <div className="col-lg-6">
                            <span className='p-3 fw-bold'>Update Service</span>
                        </div>

                    </div>
                </section>
                <section className='card mt-2'>
                    <div className='blog-d' >
                        <ToastContainer />
                        <Form className='row m-2' onSubmit={handleSubmit(onUpdate)}>
                            <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" placeholder="Name" id="title" value={name}
                                    {...register("Name", {
                                        required: false,
                                        onChange: (e) => setName(e.target.value)
                                    })}
                                />

                                {errors.Name && (
                                    <div className='errMsg text-danger'>Name is required</div>
                                )}

                            </Form.Group>

                            <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-6 col-6">
                                <Form.Label>CTA</Form.Label>
                                <Form.Control type="text" placeholder='CTA' value={cta}
                                    {...register("CTA", {
                                        required: false,
                                        onChange: (e) => setCTA(e.target.value)
                                    })}
                                />

                                {errors.CTA && (
                                    <div className='errMsg text-danger'>CTA is required</div>
                                )}

                            </Form.Group >
                            <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                                <Form.Label>Title</Form.Label>
                                <Form.Control type="text" placeholder="Title" id="title" value={title}
                                    {...register("Title", {
                                        required: false,
                                        onChange: (e) => setTitle(e.target.value)
                                    })}
                                />

                                {errors.Title && (
                                    <div className='errMsg text-danger'>Title is required</div>
                                )}

                            </Form.Group>

                            <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                                <Form.Label>Heading</Form.Label>
                                <Form.Control type="text" placeholder="Heading" id="heading" value={heading}
                                    {...register("Heading", {
                                        required: false,
                                        onChange: (e) => setHeading(e.target.value)
                                    })}
                                />

                                {errors.Heading && (
                                    <div className='errMsg text-danger'>Heading is required</div>
                                )}

                            </Form.Group>

                            <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                                <Form.Label>Slug</Form.Label>
                                <Form.Control type="text" disabled placeholder="Slug" id="heading" value={slug}
                                    {...register("Slug", {
                                        required: false,
                                        // onChange: (e) => setSlug(e.target.value)
                                    })}
                                />

                                {errors.Slug && (
                                    <div className='errMsg text-danger'>Slug is required</div>
                                )}

                            </Form.Group>


                            <Form.Group
                                className="mb-3"

                            >
                                {(image && image !== undefined && image != '') &&
                                    <div className='row'>
                                        <div className='col-lg-6'>
                                            <Form.Label>Image</Form.Label>
                                            <Form.Control type="file" id="file"
                                                {...register("Image", {
                                                    required: false,
                                                    onChange: (e) => setUpdatedImage(e.target.files[0])
                                                })}
                                            />

                                            {errors.Image && (
                                                <div className='errMsg text-danger'>Please Select Image</div>
                                            )}

                                        </div>

                                        <div className='col-lg-6 text-center mb-3 mt-3'>
                                            <Form.Label>Image Preview</Form.Label>
                                            <div className='p-3'>

                                                {updatedImage == "" ?

                                                    <img src={`${apiUrl}/static/assets/${image}`} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                                                    :
                                                    <img src={URL.createObjectURL(updatedImage)} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                                {/* {(video && video !== undefined && video != '') &&
                                    <div className='row'>
                                        <div className='col-lg-6'>
                                            <Form.Label>Video</Form.Label>
                                            <Form.Control
                                                type="file"
                                                placeholder="Video"
                                                {...register("Video", {
                                                    required: false,
                                                    onChange: (e) => setUpdatedVideo(e.target.files[0])
                                                })}
                                            />

                                            {errors.Video && (
                                                <div className='errMsg text-danger'>Video is required</div>
                                            )}
                                        </div>

                                        <div className='col-lg-6 text-center mb-3 mt-3'>
                                            <Form.Label>Media Preview</Form.Label>
                                            <div className='p-3'>
                                                {updatedVideo === "" ?
                                                    <video src={`${apiUrl}/static/assets/${video}`} controls style={{ maxWidth: "300px", maxHeight: "300px" }} /> :
                                                    <video src={URL.createObjectURL(updatedVideo)} controls style={{ maxWidth: "300px", maxHeight: "300px" }} />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                } */}
                            </Form.Group>


                            <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                                <Form.Label>Content</Form.Label>
                                <TextEditor data={updatedContent} setUpdatedContent={setUpdatedContent} />
                            </Form.Group>

                            <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                                <Form.Label>Description</Form.Label>
                                <TextEditor data={updatedDescription} setUpdatedContent={setUpdatedDescription} />
                            </Form.Group>



                            <div className="col-lg-12">
                                <span className=' fw-bold'>SEO Section</span>
                            </div>

                            <Form.Group className="mt-2 mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                                <Form.Label>SEO Title</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="SEO Title"
                                    value={seo_title}
                                    {...register("Seo_title", {
                                        required: false,
                                        onChange: (e) => setSeo_title(e.target.value)
                                    })}
                                />

                                {errors.Seo_title && (
                                    <div className='errMsg text-danger'>Seo_title is required</div>
                                )}
                            </Form.Group>

                            <Form.Group className="mt-2 mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                                <Form.Label>Canonical</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Canonical"
                                    value={canonical}
                                    onChange={(e) => setCanonical(e.target.value)}

                                />

                            </Form.Group>

                            <Form.Group className="mt-2 mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                                <Form.Label htmlFor="Robot">Robot</Form.Label>
                                <Form.Select
                                    id="Robot"
                                    aria-label="Default select example"
                                    value={robot}
                                    onChange={(e) => setRobot(e.target.value)}

                                >

                                    <option value="index,follow" >Index,Follow</option>
                                    <option value="index,no-follow">Index,No-Follow</option>
                                    <option value="no-index,follow">No-Index,Follow</option>
                                    <option value="no-index,no-follow">No-Index,No-Follow</option>
                                </Form.Select>

                            </Form.Group>

                            <Form.Group className="mt-2 mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                                <Form.Label>Script</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Script"
                                    value={script}
                                    onChange={(e) => setScript(e.target.value)}

                                />

                            </Form.Group>

                            <Form.Group
                                className="mb-3"

                            >

                                <Form.Label>Feature Image</Form.Label>
                                <Form.Control type="url" name="file"
                                    onChange={(e) => Setfeature_image(e.target.value)} />


                            </Form.Group>

                            <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                                <Form.Label>SEO Description</Form.Label>
                                <Form.Control as="textarea" placeholder="SEO Description"
                                    value={seo_description}
                                    onChange={(e) => setSeo_description(e.target.value)} />

                            </Form.Group>




                            <div className='btn-d d-flex justify-content-center'>
                                <Button type='submit' className='text-white' variant='contained'>
                                    Update Service
                                </Button>
                            </div>
                        </Form>
                    </div>

                </section>
                {/* 
                <button onClick={handleDelete}>
         Delete
      </button> */}


            </div>
        </>
    )
}

export default ServiceUpdate