// import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useForm, Controller } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import { Axios } from '../components/common/Axios';
import { ToastContainer, toast } from 'react-toastify';
import { useParams } from 'react-router-dom';


const Profile = () => {
  // const { id } = useParams();
  const [username, setName] = useState('');
  const [email, setEmail] = useState('');
  const [pending, setPending] = useState(true);
  // const [password, setPassword] = useState('');
  const [id, setID] = useState('');
 


  const addprofileSuccess = () => toast.info("Profile Updated Successfully", { position: "top-center", autoClose: 5000 });
  const addprofileError = () => toast.error("Something went wrong", { position: "top-center", autoClose: 5000 });

  const getData = () => {
    Axios.get(`/api/admin/profile`)
      .then((response) => {
        const profileData = response.data;
        if (profileData) {
          setEmail(profileData.email);
          setName(profileData.username);
          setID(profileData.id);
        }
      })
      .catch((err) => {
        console.log(err);
       
      });
  };

  useEffect(() => {
    getData();

  }, [id]);


  
  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = (profileData) => {
    setPending(true);
  
    const formData = new FormData();
    // formData.append("file", updatedImage);
  
    Axios.put(`/api/admin/profile/${id}`, {
        email: email,
        username: username,
        
      })
      .then((response) => {
        if (response.status === 200) {
         
          setEmail('');
          setName('');
          
          addprofileSuccess();
          setTimeout(() => {
            getData();
          }, 1500);
        }
      })
      .catch((err) => {
        addprofileError();
        console.error(err);
      });
  };


  return (
    <>
      <div className='profile-page'>
        <Helmet>
          <title>Profile</title>
        </Helmet>
        <section className="wrapper _profile">
          <div className="row">
            <div className="col-lg-12">
              <span className='p-3 fw-bold'>Profile</span>
            </div>
          </div>
        </section>
        <section className='profile-s card mt-3'>
          <div className='profile-d' >
            <ToastContainer />
            <Form className='row m-2' onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" placeholder="Name" id="username" value={username}
                  {...register("Name", {
                    required: false,
                    onChange: (e) => setName(e.target.value)
                  })}
                />

                {errors.Name && (
                  <div className='errMsg text-danger'>Name is required</div>
                )}

              </Form.Group>

              <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="name@gmail.com" id="email" value={email}
                  {...register("Email", {
                    required: false,
                    onChange: (e) => setEmail(e.target.value)
                  })}
                />

                {errors.Email && (
                  <div className='errMsg text-danger'>Email is Required</div>
                )}

              </Form.Group>

              {/* <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                <Form.Label>Change Password</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="******"
                  value={password}
                  pattern="[0-9]{12}"
                  {...register("Password", {
                    required: false,
                    onChange: (e) => setPassword(e.target.value),
                  })}
                />
                {errors.Contact && (
                  <div className='errMsg text-danger'>Password is Required</div>
                )}
              </Form.Group> */}


              <div className='btn-d d-flex justify-content-center'>
                <Button type='submit' className='text-white' variant='contained'>
                  Update
                </Button>
              </div>
            </Form>
          </div>
        </section>
      </div>
    </>
  );
};

export default Profile;
