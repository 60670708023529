import React, { useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import { NavLink } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { SidebarItems } from '../Sidebar/Sidebar';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/Logo_White.svg'
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import { Axios } from '../components/common/Axios';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
        ...(window.innerWidth < 767 && {
            marginLeft: 0,
        }),
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && window.innerWidth > 767 && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const DashboardLayout = () => {

    const theme = useTheme();
    const [open, setOpen] = React.useState(window.innerWidth > 767 ? true : false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [confidential, setConfidential] = useState('');


    const styles = {
        backgroundColor: "#0C0833", // Apply primary color as background
        color: '#fff', // Example: Set text color to white
    };


    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const openMenu = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    // const navigate = useNavigate();
    const handleClose = (e) => {

        setAnchorEl(null);
    };
    const navigate = useNavigate();


    const [openCollapse, setOpenCollapse] = React.useState(false);

    const handleCollapse = (collapse) => {
        setOpenCollapse((PrevOpenCollapse) => PrevOpenCollapse == collapse ? false : collapse);
    };

    const handleLogOut = () => {
        localStorage.removeItem("auth");
        navigate('/');
        // window.location.reload(false);
    }

    const getData = async () => {
        try {
            const isAdmin = await Axios.get('/api/admin/current');
            let admin = isAdmin?.data?.account;
        } catch (error) {
            // Handle errors
            console.error('Error:', error);
            handleLogOut();
        }
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="fixed" open={open} className='header' style={styles}>
                    <Toolbar className='justify-content-between'>
                        <div className='left_h d-flex align-items-center'>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                sx={{ mr: 2, ...(open && { display: 'none' }) }}
                            >
                                <MenuIcon />
                            </IconButton>
                            {/* <Typography variant="h6" noWrap component="div"> */}
                            {open ? null : <img src={`${logo}`} width="80px" alt="image" />}
                            {/* </Typography> */}
                        </div>

                        <Tooltip title="Account settings">
                            <IconButton
                                onClick={handleClick}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={openMenu ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={openMenu ? 'true' : undefined}
                            >

                                <Avatar sx={{ width: 32, height: 32 }} >A</Avatar>


                            </IconButton>
                        </Tooltip>

                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={openMenu}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >

                            <Link to="/profile" style={{ textDecoration: 'none', color: 'black' }}>
                                <MenuItem
                                // onClick={handleProfile}
                                >

                                    <Avatar /> Profile
                                </MenuItem>
                            </Link>

                            <Divider />

                            <Link to="/setting" style={{ textDecoration: 'none', color: 'black' }}>
                                <MenuItem
                                // onClick={handleSettings}
                                >
                                    <ListItemIcon >
                                        <Settings fontSize="small" className='' />
                                    </ListItemIcon>
                                    Settings
                                </MenuItem>
                            </Link>
                            <MenuItem
                                onClick={handleLogOut}
                            >
                                <ListItemIcon>
                                    <Logout fontSize="small" className='text-black' />
                                </ListItemIcon>
                                Logout
                            </MenuItem>
                        </Menu>

                    </Toolbar>
                </AppBar>
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                            background: '#0C0833'
                        },
                    }}
                    variant={window.innerWidth > 767 ? "persistent" : "temporary"}
                    anchor="left"
                    open={open}
                    className="scroll_"

                >
                    <DrawerHeader className='justify-content-between'>
                        <img src={`${logo}`} width="60px" alt="image" />
                        <IconButton
                            onClick={handleDrawerClose}
                            className="nav_bar">
                            {theme.direction === 'ltr' ? <ChevronLeftIcon style={{ color: '#fff' }} /> : <ChevronRightIcon />}
                        </IconButton>

                    </DrawerHeader>
                    <Divider />
                    <List style={styles}>
                        {SidebarItems?.map((data, index) => (
                            <>
                                {data.submenu ?
                                    <>
                                        <ListItemButton onClick={() => handleCollapse(`collapse${index + 1}`)}>
                                            <ListItemIcon className='text-white'>
                                                {data.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={data.title} />
                                            {openCollapse === `collapse${index + 1}` ? <ExpandLess /> : <ExpandMore />}
                                        </ListItemButton>
                                        {data.submenu?.map((item) => {
                                            return (
                                                <Collapse key={index} in={openCollapse === `collapse${index + 1}` ? true : false} timeout="auto" unmountOnExit>
                                                    <NavLink end to={`${item.url}`}
                                                        // onClick={data.title == 'Confidential Form' ? handleConfidentialCount : data.title == "Appointments" ? handleReadAppointmentCount : data.title == "Cancel Request" ? handleReadCancelCount : data.title == 'Smoking Form' ? handleSmokingCount : data.title == 'Happiness Details Form' ? handleHappinessCount : data.title == 'Optional Modules Form' ? handleOptionalCount : data.title == 'Weight Release Form' ? handleWeightCount : window.innerWidth < 992 ? handleDrawerClose : null}
                                                        activeClassName="active" className={"text-decoration-none text-white"}>
                                                        <ListItem disablePadding>
                                                            <List component="div" disablePadding className="w-100">
                                                                <ListItemButton sx={{ pl: 4 }}>
                                                                    {/* <ListItemIcon className='text-white'>
                                                                        {item.icon}
                                                                    </ListItemIcon> */}
                                                                    <ListItemText primary={item.title} />
                                                                </ListItemButton>
                                                            </List>
                                                        </ListItem>
                                                    </NavLink>
                                                </Collapse>
                                            )
                                        })}
                                    </>
                                    :
                                    <NavLink end to={`${data.url}`}
                                        // onClick={data.title == 'Confidential Form' ? handleConfidentialCount : data.title == "Appointments" ? handleReadAppointmentCount : data.title == "Cancel Request" ? handleReadCancelCount : data.title == 'Smoking Form' ? handleSmokingCount : data.title == 'Happiness Details Form' ? handleHappinessCount : data.title == 'Optional Modules Form' ? handleOptionalCount : data.title == 'Weight Release Form' ? handleWeightCount : window.innerWidth < 992 ? handleDrawerClose : null}
                                        activeClassName="active" className={"text-decoration-none text-white"}>
                                        <ListItem disablePadding>
                                            <ListItemButton>
                                                <ListItemIcon className='text-white' >
                                                    {data.icon}
                                                </ListItemIcon>
                                                <ListItemText className='text-white' primary={data.title} />
                                                {/* {data.title == 'Confidential Form' ? <span classNAme={confidential == "" ? "d-none" : "icon-button__badge"}>{confidential}</span> : data.title == 'Smoking Form' ? <span classNAme={smoking == "" ? "d-none" : "icon-button__badge"}>{smoking}</span> : data.title == 'Happiness Details Form' ? <span classNAme={happiness == "" ? "d-none" : "icon-button__badge"}>{happiness}</span> : data.title == 'Optional Modules Form' ? <span classNAme={optional == "" ? "d-none" : "icon-button__badge"}>{optional}</span> : data.title == 'Weight Release Form' ? <span classNAme={weight == "" ? "d-none" : "icon-button__badge"}>{weight}</span> :
                                            data.title == 'Appointments' ? <span classNAme={appointmentCount == "" ? "d-none" : "icon-button__badge"}>{appointmentCount}</span> : data.title == 'Cancel Request' ? <span classNAme={cancelCount == "" ? "d-none" : "icon-button__badge"}>{cancelCount}</span> :
                                                null} */}

                                            </ListItemButton>

                                        </ListItem>
                                    </NavLink>
                                }


                            </>
                        ))}


                    </List>

                </Drawer>
                <Main open={open} className='admin_main overflow-hidden' >
                    <DrawerHeader />

                    <Outlet />

                    {/* <Dashboard /> */}

                    <center className='pt-5'>
                        <p>
                            Copyright © {new Date().getFullYear()} Technoidols - All rights reserved
                        </p>
                    </center>
                </Main>
            </Box>
        </>
    )
}

export default DashboardLayout