import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';
import Form from 'react-bootstrap/Form';
import { ToastContainer, toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import TextEditor from '../../Editor/TextEditor';
import { Axios } from '../../components/common/Axios';
import FirstSection from '../sections/FirstSection';
import SecondSection from '../sections/SecondSection';
import ThirdSection from '../sections/ThirdSection';
import FourthSection from '../sections/FourthSection';

const SubServiceUpdate = () => {

    const apiUrl = process.env.REACT_APP_API_URL;

    const { id } = useParams();
    const [services, setServices] = useState([])
    const [name, setName] = useState('');
    const [cta, setCTA] = useState('');
    const [heading, setHeading] = useState('');
    const [title, setTitle] = useState('');
    const [media, setMedia] = useState('');
    const [updatedMedia, setUpdatedMedia] = useState('');
    const [updatedContent, setUpdatedContent] = useState('');
    const [updatedDescription, setUpdatedDescription] = useState('');
    const [seo_title, setSeo_title] = useState('');
    const [canonical, setCanonical] = useState('');
    const [robot, setRobot] = useState('Index,Follow');
    const [script, setScript] = useState('');
    const [feature_image, setFeature_image] = useState('');
    const [seo_description, setSeo_description] = useState('');
    const [parentService, setParentService] = useState('');
    const [pending, setPending] = useState(true);
    const [type, setType] = useState('');
    const [ytId, setYtId] = useState('');

    // section fields
    const [section1, setSection1] = useState({
        title: '',
        heading: '',
        content: '',
        description: '',
        image: '',
    });
    const [section2, setSection2] = useState({
        title: '',
        heading: '',
        content: '',
        description: '',
        cta: '',
        image: '',
    });
    const [section3, setSection3] = useState({
        title: '',
        heading: '',
        content: '',
        description: '',
        image: '',
    });
    const [section4, setSection4] = useState({
        image: '',
        content: '',
        description: '',
    });


    const handleClose = () => {
        setName('');
        setCTA('');
        setTitle('');
        setHeading('');
        setUpdatedMedia('');
        setUpdatedContent('');
        setUpdatedDescription('');
        setSeo_title('');
        setCanonical('');
        setRobot('');
        setScript('');
        setFeature_image('');
        setSeo_description('');
        setParentService('');
        setType('');
    }

    const getData = async () => {

        try {
            const serviceData = await Axios.get(`/api/service/getServiceIdName`);
            let service = serviceData.data.data;
            setServices(service);

        } catch (error) {
            // Handle errors
            console.error('Error:', error);
        }

        await Axios.get(`/api/service/sub-service/${id}`)
            .then((response) => {
                const serviceData = response.data;
                if (serviceData) {
                    setName(serviceData.name);
                    setCTA(serviceData.cta);
                    setTitle(serviceData.title);
                    setHeading(serviceData.heading);
                    setMedia(serviceData.media);
                    setUpdatedContent(serviceData.content);
                    setUpdatedDescription(serviceData.description);
                    setSeo_title(serviceData.seo_title);
                    setCanonical(serviceData.canonical);
                    setRobot(serviceData.robot);
                    setScript(serviceData.script);
                    setFeature_image(serviceData.feature_image);
                    setSeo_description(serviceData.seo_description);
                    setParentService(serviceData.parentService);
                    setType(serviceData.type);
                }
            })
            .catch((err) => {
                console.log(err);
            });

        try {
            const sectionData = await Axios.get(`/api/section/sub-service/section/${id}`);
            let sections = sectionData.data.data;
            if (sections?.length > 0) {
                let sectionsData = sections?.map((section) => {
                    switch (section.position) {
                        case 1 || '1':
                            setSection1({
                                title: section.title,
                                heading: section.heading,
                                content: section.content,
                                description: section.description,
                                id: section._id
                            });
                            break;
                        case 2 || '2':
                            setSection2({
                                title: section.title,
                                heading: section.heading,
                                content: section.content,
                                description: section.description,
                                cta: section.cta,
                                image: section.image

                            });
                            break;
                        case 3 || '3':
                            setSection3({
                                title: section.title,
                                heading: section.heading,
                                content: section.content,
                                description: section.description,
                                cta: section.cta,
                                image: section.image
                            });
                            break;
                        case 4 || '4':
                            setSection4({
                                image: section.image,
                                content: section.content,
                                description: section.description,
                            });
                            break;
                    }
                })
            }

        } catch (error) {
            // Handle errors
            console.error('Error:', error);
        } finally {
            setPending(false)
        }

    };

    useEffect(() => {
        getData();
        window.scrollTo(0, 0);
    }, [id]);


    const updateServiceSuccess = () => toast.info(" Sub Service Updated Successfully", { position: "top-center", autoClose: 2000, });
    const updateServiceError = () => toast.error("Something went wrong", { position: "top-center", autoClose: 2000, });
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onUpdate = (serviceData) => {
        setPending(true);

        const formData = new FormData();
        // formData.append("file", updatedImage);

        Axios.put(`/api/service/sub-service/${id}`, {
            name: name,
            cta: cta,
            title: title,
            heading: heading,
            media: type == 3 ? ytId : updatedMedia,
            content: updatedContent,
            description: updatedDescription,
            seo_title: seo_title,
            canonical: canonical,
            robot: robot,
            script: script,
            feature_image: feature_image,
            seo_description: seo_description,
            parentService: parentService,
            type: type,
        }, {
            headers: {
                // 'Content-Type': 'application/x-www-form-urlencoded',
                'Content-Type': 'multipart/form-data',
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    handleClose();
                    updateServiceSuccess();
                    setTimeout(() => {
                        getData();
                    }, 1500);
                }
            })
            .catch((err) => {
                updateServiceError();
                console.error(err);
            });
    }
    return (
        <>
            <div className='profile-page'>
                <Helmet>
                    <title>Update Sub-Service</title>
                </Helmet>
                <section className="wrapper _blogs">
                    <div className="row">
                        <div className="col-lg-6">
                            <span className='p-3 fw-bold'>Update Sub-Service</span>
                        </div>

                    </div>
                </section>
                <section className='card mt-2'>
                    <div className='blog-d' >
                        <ToastContainer />
                        <Form className='row m-2' onSubmit={handleSubmit(onUpdate)}>

                            <Form.Group className=" mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                                <Form.Label htmlFor="parentService">ParentService</Form.Label>
                                <Form.Select
                                    id="parentService"
                                    aria-label="Default select example"
                                    value={parentService}

                                    {...register("ParentService", {
                                        required: false,
                                        onChange: (e) => setParentService(e.target.value)
                                    })}
                                >

                                    {services?.map((service, index) => (
                                        <option value={service._id} key={index}>{service.name}</option>
                                    ))}

                                </Form.Select>
                                {errors.ParentService && (
                                    <div className='errMsg text-danger'>ParentService is required</div>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" placeholder="Name" id="title" value={name}
                                    {...register("Name", {
                                        required: false,
                                        onChange: (e) => setName(e.target.value)
                                    })}
                                />

                                {errors.Name && (
                                    <div className='errMsg text-danger'>Name is required</div>
                                )}

                            </Form.Group>

                            <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                                <Form.Label>Title</Form.Label>
                                <Form.Control type="text" placeholder="Title" id="title" value={title}

                                    {...register("Title", {
                                        required: false,
                                        onChange: (e) => setTitle(e.target.value)
                                    })}
                                />

                                {errors.Title && (
                                    <div className='errMsg text-danger'>Title is required</div>
                                )}

                            </Form.Group>

                            <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                                <Form.Label>Heading</Form.Label>
                                <Form.Control type="text" placeholder="Heading" id="heading" value={heading}
                                    {...register("Heading", {
                                        required: false,
                                        onChange: (e) => setHeading(e.target.value)
                                    })}
                                />

                                {errors.Heading && (
                                    <div className='errMsg text-danger'>Heading is required</div>
                                )}

                            </Form.Group>




                            <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                                <Form.Label>CTA</Form.Label>
                                <Form.Control type="text" placeholder='CTA' value={cta}
                                    {...register("CTA", {
                                        required: false,
                                        onChange: (e) => setCTA(e.target.value)
                                    })}
                                />

                                {errors.CTA && (
                                    <div className='errMsg text-danger'>CTA is required</div>
                                )}

                            </Form.Group>
                            <Form.Group className="mt-2 mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                                <Form.Label htmlFor="Type">Media Type</Form.Label>
                                <Form.Select
                                    id="Type"
                                    aria-label="Default select example"
                                    value={type}
                                    {...register("type", {
                                        required: false,
                                        onChange: (e) => setType(e.target.value)
                                    })}

                                >
                                    <option value="" >Select Type</option>
                                    <option value="1">Image</option>
                                    <option value="2">Video</option>
                                    <option value="3">YoutubeVideo</option>

                                </Form.Select>
                                {errors.type && (
                                    <div className='errMsg text-danger'>Type is required</div>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3">
                                {(type == 1 || type == 2) ?
                                    <div className='row'>
                                        <div className='col-lg-6'>
                                            <Form.Label>Media</Form.Label>
                                            <Form.Control type="file" id="file" accept={type == 2 ? 'video/mp4,video/x-m4v,video/*' : 'image/png,image/gif,image/jpeg,image/webp'}
                                                {...register("Image", {
                                                    required: false,
                                                    onChange: (e) => setUpdatedMedia(e.target.files[0])
                                                })}
                                            />

                                            {errors.Image && (
                                                <div className='errMsg text-danger'>Please Select Image</div>
                                            )}

                                        </div>

                                        <div className='col-lg-6 text-center mb-3 mt-3'>
                                            <Form.Label>Media Preview</Form.Label>
                                            <div className='p-3'>
                                                {updatedMedia === "" ? (
                                                    type == 1 ?
                                                        <img src={`${apiUrl}/static/assets/${media}`} style={{ maxWidth: "300px", maxHeight: "300px" }} /> :
                                                        type == 2 ?
                                                            <video src={`${apiUrl}/static/assets/${media}`} controls style={{ maxWidth: "300px", maxHeight: "300px" }} /> : null) :
                                                    (type == 1 ?
                                                        <img src={URL.createObjectURL(updatedMedia)} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                                                        : type == 2 ?
                                                            <video src={URL.createObjectURL(updatedMedia)} controls style={{ maxWidth: "300px", maxHeight: "300px" }} />
                                                            : null)}
                                            </div>
                                        </div>
                                    </div>
                                    : type == 3 ?
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                <Form.Label>Youtube Video Id</Form.Label>
                                                <Form.Control type="text" placeholder='Youtube Video Id' value={ytId}
                                                    {...register("YtID", {
                                                        required: false,
                                                        onChange: (e) => setYtId(e.target.value)
                                                    })}
                                                />
                                                {errors.YtID && (<div className='errMsg text-danger'>'Youtube Video Id is required</div>)}
                                            </div>
                                            <div className='col-lg-6 text-center mb-3 mt-3'>
                                                <Form.Label>Image Preview</Form.Label>
                                                <div className='p-3'>
                                                    < iframe
                                                        src={`https://www.youtube.com/embed/VIDEO_ID?autoplay=1&controls=0&mute=1&loop=1&playlist=${ytId == '' ? media : ytId}&rel=0&modestbranding=1&showinfo=0`}
                                                        frameborder="0"
                                                        allowfullscreen
                                                        className="yt-video w-100"
                                                        style={{ pointerEvents: 'none', maxWidth: "300px", maxHeight: "300px" }}
                                                    ></iframe>
                                                </div>
                                            </div>
                                        </div> : null}
                            </Form.Group>

                            <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                                <Form.Label>Content</Form.Label>
                                <TextEditor data={updatedContent} setUpdatedContent={setUpdatedContent} />
                            </Form.Group>

                            <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                                <Form.Label>Description</Form.Label>
                                <TextEditor data={updatedDescription} setUpdatedContent={setUpdatedDescription} />
                            </Form.Group>

                            <div className="col-lg-12">
                                <span className=' fw-bold'>SEO Section</span>
                            </div>

                            <Form.Group className="mt-2 mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                                <Form.Label>SEO Title</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="SEO Title"
                                    value={seo_title}
                                    {...register("Seo_title", {
                                        required: false,
                                        onChange: (e) => setSeo_title(e.target.value)
                                    })}
                                />

                                {errors.Seo_title && (
                                    <div className='errMsg text-danger'>Seo_title is required</div>
                                )}
                            </Form.Group>

                            <Form.Group className="mt-2 mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                                <Form.Label>Canonical</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Canonical"
                                    value={canonical}
                                    onChange={(e) => setCanonical(e.target.value)}

                                />

                            </Form.Group>

                            <Form.Group className="mt-2 mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                                <Form.Label htmlFor="Robot">Robot</Form.Label>
                                <Form.Select
                                    id="Robot"
                                    aria-label="Default select example"
                                    value={robot}
                                    onChange={(e) => setRobot(e.target.value)}

                                >

                                    <option value="index,follow" >Index,Follow</option>
                                    <option value="index,no-follow">Index,No-Follow</option>
                                    <option value="no-index,follow">No-Index,Follow</option>
                                    <option value="no-index,no-follow">No-Index,No-Follow</option>
                                </Form.Select>

                            </Form.Group>

                            <Form.Group className="mt-2 mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                                <Form.Label>Script</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Script"
                                    value={script}
                                    onChange={(e) => setScript(e.target.value)}

                                />

                            </Form.Group>

                            <Form.Group
                                className="mb-3"

                            >

                                <Form.Label>Feature Image</Form.Label>
                                <Form.Control type="url" name="file"
                                    onChange={(e) => setFeature_image(e.target.value)} />


                            </Form.Group>

                            <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                                <Form.Label>SEO Description</Form.Label>
                                <Form.Control as="textarea" placeholder="SEO Description"
                                    value={seo_description}
                                    onChange={(e) => setSeo_description(e.target.value)} />

                            </Form.Group>



                            <div className='btn-d d-flex justify-content-center'>
                                <Button type='submit' className='text-white' variant='contained'>
                                    Update
                                </Button>
                            </div>
                        </Form>
                    </div>

                </section>


                {/* section  */}
                <section className='sub-service-s'>
                    <div className='mt-3'>
                        <span className='h3 fw-bold'>Section First</span>
                    </div>
                    <div className='card cardSectionBorder mt-3'>
                        <FirstSection section={section1} parent={id} />
                    </div>
                    <div className='mt-3'>
                        <span className='h3 fw-bold'>Section Second</span>
                    </div>
                    <div className='card cardSectionBorder mt-3'>
                        <SecondSection section={section2} parent={id} />
                    </div>
                    <div className='mt-3'>
                        <span className='h3 fw-bold'>Section Third</span>
                    </div>
                    <div className='card cardSectionBorder mt-3'>
                        <ThirdSection section={section3} parent={id} />
                    </div>
                    <div className='mt-3'>
                        <span className='h3 fw-bold'>Section Fourth</span>
                    </div>
                    <div className='card cardSectionBorder mt-3'>
                        <FourthSection section={section4} parent={id} />
                    </div>
                </section>

            </div>
        </>
    )
}

export default SubServiceUpdate