import { Button } from 'react-bootstrap';
import { useState, useEffect } from 'react'
import TextEditor from '../Editor/TextEditor';
import Form from 'react-bootstrap/Form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from "react-helmet";
import { useForm, Controller } from 'react-hook-form';

import { useParams } from 'react-router-dom';
import { Axios } from '../components/common/Axios';
import { notify, notifyErr } from '../components/common/norify';

const PrivacyPolicy = () => {

    const apiUrl = process.env.REACT_APP_API_URL;

    const [id, setId] = useState('')
    const [updatedContent, setUpdatedContent] = useState('');
    const [pending, setPending] = useState(true);
    const [formData, setFormData] = useState({
        content: '',
        seo_title: '',
        canonical: '',
        robot: '',
        script: '',
        feature_image: '',
        seo_description: '',
    })

    const handleClose = () => {
        setFormData({
            content: '',
            seo_title: '',
            canonical: '',
            robot: '',
            script: '',
            feature_image: '',
            seo_description: '',
        });
        reset({
            content: '',
            seo_title: '',
            canonical: '',
            robot: '',
            script: '',
            feature_image: '',
            seo_description: '',
        })
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        handleInputChange('content', updatedContent);
    }, [updatedContent])


    const handleInputChange = (fieldName, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [fieldName]: value,
        }));
    };

    const getData = () => {
        Axios.get(`api/privacy`)
            .then((response) => {
                let [privacyData] = response.data.data;
                if (privacyData) {
                    setFormData({
                        content: privacyData.content,
                        seo_title: privacyData.seo_title,
                        canonical: privacyData.canonical,
                        robot: privacyData.robot,
                        seo_script: privacyData.seo_script,
                        feature_image: privacyData.feature_image,
                        seo_description: privacyData.seo_description,
                    })
                    setId(privacyData._id);
                    setUpdatedContent(privacyData.content);
                    reset({
                        content: privacyData.content,
                        seo_title: privacyData.seo_title,
                        canonical: privacyData.canonical,
                        robot: privacyData.robot,
                        seo_script: privacyData.seo_script,
                        feature_image: privacyData.feature_image,
                        seo_description: privacyData.seo_description,
                    })
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        getData();
        window.scrollTo(0, 0);
    }, [id]);

    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const onSubmit = () => {
        setPending(true);

        Axios.put(`api/privacy/${id}`, formData, {
            method: "POST",
            // headers: {
            //     "Content-Type": "multipart/form-data"
            // },
        })
            .then((response) => {
                if (response.status === 200) {
                    handleClose();
                    notify("Updated Successfully")
                    setTimeout(() => {
                        getData();
                    }, 1500);
                }
            })
            .catch((err) => {
                notifyErr("Something went wrong")
                console.error(err);
            });

    };

    return (
        <div className='profile-page'>
            <Helmet>
                <title>Privacy Policy</title>
            </Helmet>
            <section className="wrapper _profile">
                <div className="row">
                    <ToastContainer />
                    <div className="col-lg-12">
                        <span className='p-3 fw-bold'>Privacy Policy</span>
                    </div>
                </div>
            </section>
            <section classNAme="card  cardSectionBorder mt-3">
                <div className=''>
                    <Form className='row m-2' onSubmit={handleSubmit(onSubmit)}>

                        <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                            <Form.Label>Content</Form.Label>
                            <TextEditor data={updatedContent} setUpdatedContent={setUpdatedContent} />
                        </Form.Group>

                        <hr></hr>
                        <div className="col-lg-12">
                            <span className=' fw-bold'>SEO Section</span>
                        </div>

                        <Form.Group className="mt-2 mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                            <Form.Label>SEO Title</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="SEO Title"
                                value={formData.seo_title}
                                {...register("Seo_title", {
                                    required: true,
                                    onChange: (e) => handleInputChange('seo_title', e.target.value)
                                })}
                            />

                            {errors.Seo_title && (
                                <div className='errMsg text-danger'>SEO title is required</div>
                            )}
                        </Form.Group>

                        <Form.Group className="mt-2 mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                            <Form.Label>Canonical</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Canonical"
                                value={formData.canonical}
                                onChange={(e) => handleInputChange('canonical', e.target.value)}

                            />

                        </Form.Group>

                        <Form.Group className="mt-2 mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                            <Form.Label htmlFor="Robot">Robot</Form.Label>
                            <Form.Select
                                id="Robot"
                                aria-label="Default select example"
                                value={formData.robot}
                                onChange={(e) => handleInputChange('robot', e.target.value)}
                            >
                                <option value="" >Select</option>
                                <option value="index,follow" >Index,Follow</option>
                                <option value="index,no-follow">Index,No-Follow</option>
                                <option value="no-index,follow">No-Index,Follow</option>
                                <option value="no-index,no-follow">No-Index,No-Follow</option>
                            </Form.Select>

                        </Form.Group>

                        <Form.Group className="mt-2 mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                            <Form.Label>Script</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Script"
                                value={formData.script}
                                onChange={(e) => handleInputChange('script', e.target.value)}

                            />

                        </Form.Group>

                        <Form.Group
                            className="mt-2 mb-3 col-lg-6 col-md-12 col-sm-12 col-12"
                        >

                            <Form.Label>Feature Image</Form.Label>
                            <Form.Control type="url" name="file" value={formData.feature_image}
                                onChange={(e) => handleInputChange('feature_image', e.target.value)} />


                        </Form.Group>

                        <Form.Group className="mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                            <Form.Label>SEO Description</Form.Label>
                            <Form.Control as="textarea" placeholder="SEO Description"
                                value={formData.seo_description}
                                onChange={(e) => handleInputChange('seo_description', e.target.value)} />

                        </Form.Group>

                        <div className='btn-d d-flex justify-content-center'>
                            <Button type='submit' className=' Add_btn_ text-white' variant='contained'>
                                Update
                            </Button>
                        </div>
                    </Form>


                </div>
            </section>
        </div>
    )
}

export default PrivacyPolicy