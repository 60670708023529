import React from 'react';
import { Navigate, Routes, useRoutes, Route } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import ProtectedRoute from './components/ProtectedRoute';
import About from './pages/About';
import Error from './pages/404';
import SimpleLayout from './layouts/SimpleLayout';
import Portfolio from './pages/portfolio/Portfolio';
import PortfolioAdd from './pages/portfolio/PortfolioAdd';
import PortfolioUpdate from './pages/portfolio/PortfolioUpdate';
import Testimonials from './pages/Testimonials';
import Blogs from './pages/blog/Blogs';
import Profile from './pages/Profile';
import Setting from './pages/Setting';
import BlogUpdate from './pages/blog/BlogUpdate';
import BlogAdd from './pages/blog/BlogAdd';
import SubServices from './pages/subService/SubServices';
import Service from './pages/service/Service';
import ServiceAdd from './pages/service/ServiceAdd';
import ServiceUpdate from './pages/service/ServiceUpdate';
import SubServiceAdd from './pages/subService/SubServiceAdd';
import SubServiceUpdate from './pages/subService/SubServiceUpdate';
import Login from './pages/Login';
import Seo from './pages/Seo';
import Enquiry from './pages/Enquiry';
import ServiceCards from './pages/home/ServiceCards';
import Values from './pages/home/Values';
import HowWeWorks from './pages/home/HowWeWorks';
import WhyChooseUs from './pages/home/WhyChooseUs';
import Faqs from './pages/home/Faqs';
import SeoTechniques from './pages/SEO/SeoTechniques';
import Ranking from './pages/SEO/Ranking';
import OptimizationStrategies from './pages/SEO/OptimizationStrategies';
import ContentMarketing from './pages/SEO/ContentMarketing';
import Expertise from './pages/SEO/Expertise';
import Technologies from './pages/WebDev/Technologies';
import Approaches from './pages/WebDev/Approaches';
import WebExpertise from './pages/WebDev/WebExpertise';
import Process from './pages/WebDev/Process';
import SeoPricing from './pages/SEO/SeoPricing';
import PrivacyPolicy from './pages/PrivacyPolicy';
import PopupModel from './pages/PopupModel';
import TermCondition from './pages/TermCondition';
import Thankyou from './pages/Thankyou';
import ThankyouUpdate from './pages/ThankyouUpdate';



const Router = () => {
    let authenticated = localStorage.getItem("auth");
   
    return (
        <>
            <Routes>
                {authenticated ?
                    <Route path='/' element={<ProtectedRoute authenticated={authenticated} />}>
                        <Route index element={<Dashboard />} />
                        <Route exact path='/portfolio' element={<Portfolio />} />
                        <Route exact path='/portfolio-add' element={<PortfolioAdd />} />
                        <Route exact path='/portfolio-update/:id' element={<PortfolioUpdate />} />
                        <Route exact path='/testimonials' element={<Testimonials />} />
                        <Route exact path='/about' element={<About />} />
                        <Route exact path='/seo' element={<Seo />} />
                        <Route exact path='/enquiry' element={<Enquiry />} />
                        <Route exact path='/profile' element={<Profile />} />
                        <Route exact path='/setting' element={<Setting />} />
                        <Route exact path='/blog' element={<Blogs />} />
                        <Route exact path='/blog-add' element={<BlogAdd />} />
                        <Route exact path='/blog-update/:id' element={<BlogUpdate />} />
                        <Route exact path='/service' element={<Service />} />
                        <Route exact path='/service-update/:id' element={<ServiceUpdate />} />
                        <Route exact path='/sub-services' element={<SubServices />} />
                        <Route exact path='/subservice-add' element={<SubServiceAdd />} />
                        <Route exact path='/subservice-update/:id' element={<SubServiceUpdate />} />
                        <Route exact path='/service-card' element={<ServiceCards />} />
                        <Route exact path='/values' element={<Values />} />
                        <Route exact path='/how-we-works' element={<HowWeWorks />} />
                        <Route exact path='/why-choose-us' element={<WhyChooseUs />} />
                        <Route exact path='/faqs' element={<Faqs />} />
                        <Route exact path='/seo-techniques' element={<SeoTechniques />} />
                        <Route exact path='/ranking' element={<Ranking />} />
                        <Route exact path='/optimization-strategies' element={<OptimizationStrategies />} />
                        <Route exact path='/content-marketing' element={<ContentMarketing />} />
                        <Route exact path='/seo-expertise' element={<Expertise />} />
                        <Route exact path='/seo-pricing' element={<SeoPricing />} />
                        <Route exact path='/technologies' element={<Technologies />} />
                        <Route exact path='/approaches' element={<Approaches />} />
                        <Route exact path='/web-expertise' element={<WebExpertise />} />
                        <Route exact path='/process' element={<Process />} />
                        <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
                        <Route exact path='/term-conditions' element={<TermCondition />} />
                        <Route exact path='/popup-model' element={<PopupModel/>} />
                        <Route exact path='/thankyou' element={<Thankyou />} />
                        <Route exact path='/thankyou-update/:id' element={<ThankyouUpdate />} />

                        {/* <Route path='*' element={<Navigate to={'/'} />} /> */}
                    </Route>
                    :
                    <Route path='/*' element={<ProtectedRoute authenticated={authenticated} />}>
                        <Route index element={<Login />} />
                    </Route>
                }

            </Routes>
        </>
    );
}

export default Router